import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { backend } from "src/App";
import "./WalletSelect.css";
import { Link } from "react-router-dom";
import { GlobalStateContext } from "../../App";
import { useTranslation } from "react-i18next";

interface WalletSelectProps {
  setCurrentWallet: any;
  allWallets: any;
  popSnack: any;
  jwt: string;
}

export const WalletSelect = ({
  setCurrentWallet,
  allWallets,
  popSnack,
  jwt,
}: WalletSelectProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectWallet, setSelectWallet] = useState<{
    name: string;
    address: string;
    id: number;
    img: string;
    vault: string;
  }>({ name: "", address: "", id: -1, img: "", vault: "" });
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { t } = useTranslation("walletSelect");
  const { setGlobalVar } = context;

  function setWallet(userToken: any) {
    localStorage.setItem("wallet", userToken);
  }

  const numberOfPages = Math.ceil(allWallets.length / 2);

  let circles = [];
  for (let index = 0; index < numberOfPages; index++) {
    if (index + 1 === currentPage) {
      circles.push(<Circle fill={false} />);
    } else {
      circles.push(<Circle fill />);
    }
  }

  return (
    <div className="slider-background">
      <>
        <div className="select-wallet-head">
          <h2 style={{ color: "#078BF9" }}>{t("selectWallet")}</h2>
        </div>
        <div className="slider-head">
          <button
            className="slider-buttons"
            style={{ marginRight: "1vw" }}
            onClick={() => {
              if (currentPage - 1 >= 1) setCurrentPage(currentPage - 1);
            }}
          >
            {"<"}
          </button>
          <button
            className="slider-buttons"
            onClick={() => {
              if (currentPage + 1 <= numberOfPages)
                setCurrentPage(currentPage + 1);
            }}
          >
            {">"}
          </button>
        </div>
        <div className="select-wallet-middle">
          {allWallets.map((elm: any, idx: number) => {
            if (Math.ceil((idx + 1) / 2) === currentPage) {
              return (
                <button
                  key={idx}
                  style={{ border: "none", background: "none" }}
                  onClick={() => {
                    setSelectWallet(elm);
                  }}
                >
                  <CardDisplayer
                    wallet={elm}
                    jwt={jwt}
                    selected={selectWallet.name}
                  />
                </button>
              );
            }
          })}
        </div>
        <div className="select-wallet-foot">{circles}</div>
        <Link to="/home">
          <button
            className="select-wallet-button"
            onClick={() => {
              setCurrentWallet(selectWallet);
              setWallet(selectWallet.vault);
              setGlobalVar(selectWallet.vault);
              popSnack(t("eventchangewallet"), "success");
            }}
          >
            {t("Validate")}
          </button>
        </Link>
      </>
    </div>
  );
};

interface CircleProps {
  fill: boolean;
}

const Circle = ({ fill }: CircleProps) => {
  return <div className={fill ? "circle-filled" : "circle-not-filled"} />;
};

interface CardDisplayerProps {
  wallet: any;
  jwt: string;
  selected: string;
}

const CardDisplayer = ({ wallet, jwt, selected }: CardDisplayerProps) => {
  const [isLoaded, setLoaded] = useState(false);
  const [imgDisplay, setImg] = useState("");

  useEffect(() => {
    axios
      .get(`${backend}/files/getAdmImg/${wallet.img}`, {
        headers: { "x-api-key": process.env.REACT_APP_API, authorization: jwt },
        responseType: "arraybuffer",
      })
      .then((res) => {
        const binaryString = Array.from(new Uint8Array(res.data), (v) =>
          String.fromCharCode(v)
        ).join("");
        const theImage = btoa(binaryString);
        setImg(theImage);
        setLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  });
  return (
    <>
      {isLoaded ? (
        <Card wallet={wallet} image={imgDisplay} selected={selected} />
      ) : (
        <> </>
      )}
    </>
  );
};

interface CardProps {
  wallet: any;
  image: any;
  selected: string;
}

const Card = ({ wallet, image, selected }: CardProps) => {
  return (
    <div
      className="select-wallet-card-background"
      style={{
        background:
          selected != wallet.name
            ? "#1A223D"
            : "radial-gradient(#1A223D, #078BF9)",
      }}
    >
      <div className="insert-img">
        <img src={`data:image/png;base64,${image}`} className="swallet-card-image" />
      </div>
      <div className="card-texts">
        <span
          style={{
            fontWeight: "bold",
            color: "white",
            fontSize: "1rem",
            marginTop: "10px",
          }}
        >
          {wallet.name}
        </span>
      </div>
    </div>
  );
};

export default WalletSelect;
