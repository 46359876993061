import axios from "axios";
import { useEffect, useState } from "react";
import "../MobileApp.scss";
import crown from "src/assets/crown.png";

interface CardWalletProps {
  page: number;
  collection: any;
  isPoa: boolean;
  fromPoap: boolean;
  image: string;
}

const CardWallet = ({
  page,
  collection,
  isPoa,
  fromPoap,
  image,
}: CardWalletProps) => {
  const [isLoaded, setLoaded] = useState(true);
  const [imgDisplay, setImg] = useState("");
  const [imgReady, setReady] = useState("");

  let Nftnb = 1;
  if (!isPoa && page === 1) {
    Nftnb = collection.nfts.length;
  }

  // pb avec la propriété uri dans le json product, pas de champs image
  useEffect(() => {
    if (isPoa) {
      setImg(collection.image);
    } else {
      let img = image;
      if (image == "") {
        img = collection.image;
        if (collection.image == undefined) {
          img = collection.nfts[0].image; // Corriger ca pour plus tard
        }
      }
      setImg(img);
    }
    if (imgDisplay != "") {
      axios
        .get(`${imgDisplay}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          responseType: "arraybuffer",
        })
        .then((res) => {
          const binaryString = Array.from(new Uint8Array(res.data), (v) =>
            String.fromCharCode(v)
          ).join("");
          const theImage = btoa(binaryString);
          setReady(theImage);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoaded(true));
    }
  }, [imgDisplay]);

  return (
    <>
      {isLoaded ? (
        <Card
          page={page}
          name={collection.name}
          image={imgReady}
          fromPoap={fromPoap}
          Nftnb={Nftnb}
        />
      ) : (
        <> </>
      )}
    </>
  );
};

interface CardProps {
  page: number;
  name: string;
  image: string;
  fromPoap: boolean;
  Nftnb: number;
}

const Card = ({ page, name, image, fromPoap, Nftnb }: CardProps) => {
  return (
    <div className="wallet-card-item">
      <div className="wallet-img-bg">
        {page !== 1 ? (
          <></>
        ) : (
          <div className="card-sphere-nfts">
            <div className="card-sphere-nfts-txt">{Nftnb}</div>
          </div>
        )}
        <img
          src={`data:image/png;base64,${image}`}
          className="wallet-card-image"
        />
      </div>
      {fromPoap ? <></> : <div className="wallet-card-texts">{name}</div>}
    </div>
  );
};

export default CardWallet;
