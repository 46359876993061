import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "./SendNft.css";
import axios from "axios";
import { backend, GlobalStateContext } from "../../App";
import QRCode from "react-qr-code";
import emailjs from "@emailjs/browser";
import useFullPageLoader from "src/hooks/UseLoader";

interface SendProps {
  variant: "send" | "mint";
  collection: any;
  popSnack: any;
  setCurrent: any;
  jwt: string;
  vaultId: string;
  currWallet: any;
}

export const Send = ({
  popSnack,
  variant,
  collection,
  setCurrent,
  jwt,
  vaultId,
  currWallet,
}: SendProps) => {
  let title = "";
  const { t } = useTranslation("sendNft");
  const frontend = process.env.REACT_APP_FRONT || "http://172.20.10.4:3000";
  const urlRedirect = frontend + "/wallet?collec";
  const [email, setEmail] = useState("");
  const [price, setPrice] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  if (variant === "send") {
    title = t("send");
  } else {
    title = t("mint");
  }

  useEffect(() => {
    axios
        .post(
            `${backend}/poap/payment`,
            {
              collec: collection?.address,
              type: "poap",
            },
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API,
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((resp) => {
            if (resp.data !== 0) {
              setPrice(true);
            }
            console.log("price data", resp.data)
          })
          .catch((err) => {
            console.log(err);
            popSnack(t("errorPayment"), "error");
          });
  });

  const mailCode = () => {

    if (typeof showLoader === "function") {
      showLoader();
    } 
    axios
        .post(
            `${backend}/poap/generatecode`,
            { addr: collection.address, name: collection.eventName},
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API,
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((resp) => {
            var templateParams = {
              from_name: "Customer Service",
              code: resp.data,
              link_to: `${urlRedirect}=${collection.address}&type=poap`,
              to_email: email,
            };
        
            emailjs
              .send(
                "NFT_Experience",
                "template_n3t3uhn",
                templateParams,
                "dI1NV4PWOS0ci1eLZ"
              )
              .then(
                function (response: any) {
                  if (typeof hideLoader === "function") {
                    hideLoader();
                  }
                  popSnack("Email sent successfully", "success");
                  console.log("SUCCESS!", response.status, response.text);
                },
                function (error: any) {
                  if (typeof hideLoader === "function") {
                    hideLoader();
                  }
                  popSnack("An error occured while sending the nft by email", "error");
                  console.log("FAILED...", error);
                }
              );
          })
          .catch((err) => {
            console.log(err);
            popSnack("An error occured", "error");
            if (typeof hideLoader === "function") {
              hideLoader();
            }
          });
  }

  const mail = () => {
    if (typeof showLoader === "function") {
      showLoader();
    } 
    var templateParams = {
      from_name: "Customer Service",
      link_to: `${urlRedirect}=${collection.address}&type=poap`,
      to_email: email,
    };

    emailjs
      .send(
        "NFT_Experience",
        "template_iywots5",
        templateParams,
        "dI1NV4PWOS0ci1eLZ"
      )
      .then(
        function (response: any) {
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          popSnack("Email sent successfully", "success");
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error: any) {
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          popSnack("An error occured while sending the nft by email", "error");
          console.log("FAILED...", error);
        }
      );
  }

  return (
    <>
    <div className="send-background">
          <button
      style={{
              color: "#EF19D1",
              background: "none",
              border: "none",
              cursor: "pointer",
              textDecoration: "none",
              alignSelf: "start",
              marginLeft: "5vw",
              marginTop: "15vh",
            }}
            onClick={() => {
              setCurrent(undefined);
            }}
          >
            {"< " + t("back")}
          </button>
          <h2
            style={{
              color: "#078BF9",
              minWidth: "70vw",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            {title}
          </h2>
    <div className="mid-poap">
      <div className="mid-detail-poap">
        <div className="poap-QRcode">
          <QRCode
            size={256}
            style={{ width: "90%", margin: "10px" }}
            value={`${urlRedirect}=${collection.address}&type=poap`}
            viewBox={`0 0 256 256`}
          />
          <TextField autoComplete='off' variant="outlined" label="email" style={{ minWidth: "20vw", border: "green" }} className="txtfield-offer" value={email} onChange={(e: any) => {
                        setEmail(e.target.value)
                    }} />
          <button
            className="button-email"
            onClick={() => {
              price ? mailCode() : mail()
            }}
          >
            Envoyer
          </button>
        </div>
      </div>
    </div>
    </div>
    {loader}
    </>
  );
};

export default Send;
