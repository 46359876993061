import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import chat from "../../assets/passport/chat.png";
import priv from "../../assets/passport/privilege.png";
import histo from "../../assets/passport/historic.png";
import certif from "../../assets/passport/certif.png";
import mint from "../../assets/passport/mint.png";
import transfert from "../../assets/passport/transfert.png";
import rouage from "../../assets/passport/rouage.png";
import send from "../../assets/passport/send.png";
import poubelle from "../../assets/passport/poubelle.png";
import rappel from "../../assets/passport/rappel.png";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SendNft from "./Sendnftpass";
import emailjs from "@emailjs/browser";
import { Document, Page } from "react-pdf";
import Chat from "./ChatDirect";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./Desktop.css";
import { Delete } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { backend, GlobalStateContext } from "src/App";
import useFullPageLoader from "src/hooks/UseLoader";
import { useTranslation } from "react-i18next";

interface DesktopProps {
  nft: any;
  popSnack: any;
  allClients: any;
  page: boolean;
  setisDetail: any;
  jwt: string;
  currWallet: any;
  clientXmtp: any;
}

export const Desktop = ({
  nft,
  popSnack,
  allClients,
  page,
  setisDetail,
  jwt,
  currWallet,
  clientXmtp,
}: DesktopProps) => {
  const [isHistory, setHistory] = useState(false);
  const [sendpage, SetSendPage] = useState(false);
  const [chat, setChat] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const openCertif = () => {
    window.open(nft.certificat, "_blank");
  };
  const [histo, setHisto] = useState<{
    owner: string;
    addr: string;
    data: string[];
    id: string;
  }>({ owner: "", addr: "", data: [], id: "-1" });
  const [queryParameters] = useSearchParams();
  const addr = queryParameters.get("col") || "none";
  const id = nft.name.split("#")[1];
  const context = useContext(GlobalStateContext);
  const { t } = useTranslation("passportProduct");
  console.log("nft", nft)
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;

  const fetchHistory = async () => {
    axios
      .post(
        `${backend}/nft/history/id`,
        { coladdr: addr, id: Number(id) },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: jwt,
          },
        }
      )
      .then((resp) => {
        axios
          .post(
            `${backend}/client/getclientfromaddr`,
            { addr: resp.data.owner },
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API,
                Authorization: jwt,
              },
            }
          )
          .then((res) => {
            setHisto({
              owner: res.data,
              addr: resp.data.owner,
              data: resp.data.data,
              id: resp.data.id,
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState(0);
  const [acc, setAcc] = React.useState("");

  const handleClickOpen = (state: number) => {
    setOpen(true);
    setState(state);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const DeleteNFT = () => {
    setOpen(false);
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(
        `${backend}/nft/burn`,
        {
          contractAddr: addr,
          tokenId: id,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: jwt,
          },
        }
      )
      .then((resp) => {
        console.log(resp);
        popSnack("NFT deleted successfully", "success");
        if (typeof hideLoader === "function") {
          hideLoader();
        }
      })
      .catch((err) => {
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack("Error occured while sending the nft", "error");
        console.log(err);
      });
  };

  const GetBackNFT = () => {
    setOpen(false);
    if (typeof showLoader === "function") {
      showLoader();
    }
    console.log("histo", histo);
    axios
      .post(
        `${backend}/client/send`,
        {
          from: histo.addr,
          wallet: currWallet.address,
          contractAddr: addr,
          tokenId: id,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: jwt,
            vault: globalVar,
          },
        }
      )
      .then((resp) => {
        console.log(resp);
        popSnack("NFT sent successfully", "success");
        if (typeof hideLoader === "function") {
          hideLoader();
        }
      })
      .catch((err) => {
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack("Error occured while sending the nft", "error");
        console.log(err);
      });
  };

  useEffect(() => {
    if (histo.owner == "") {
      fetchHistory();
    }
  });

  return (
    <>
      <button
        style={{
          color: "#EF19D1",
          background: "none",
          border: "none",
          cursor: "pointer",
          textDecoration: "none",
          alignSelf: "start",
          marginLeft: "5vw",
          marginTop: "15vh",
          marginBottom: "-15vh",
        }}
        onClick={() => {
          setisDetail(-1);
        }}
      >
        {t("goBack")}
      </button>
      {!chat ? (
        !sendpage ? (
          !isHistory ? (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "55vw",
                  height: "100vh",
                  color: "white",
                }}
              >
                <DetailNft
                  nft={nft}
                  histo={histo}
                  popSnack={popSnack}
                  jwt={jwt}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    width: "16vw",
                    height: "60vh",
                    backgroundColor: "#1A223D",
                    borderRadius: "10px",
                    marginLeft: "2vw",
                  }}
                >
                  <div className="btn-content">
                    <div className="btn-column">
                      <UtilityButton handleClick={openCertif} type="certificat" />
                      <UtilityButton
                        handleClick={() => {
                          setHistory(true);
                        }}
                        type="historic"
                      />
                      <UtilityButton handleClick={() => (console.log("privilege"))} type="privilege" />
                    </div>
                    <div className="btn-column">
                      <UtilityButton handleClick={() => { setChat(true) }} type="chat" />
                      {page ? (
                        <UtilityButton
                          handleClick={() => handleClickOpen(0)}
                          type="trash"
                        />
                      ) : (
                        <UtilityButton
                          handleClick={() => handleClickOpen(1)}
                          type="callback"
                        />
                      )}

                      <UtilityButton
                        handleClick={() => SetSendPage(true)}
                        type="send"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <History
              popSnack={popSnack}
              nft={nft}
              histo={histo}
              setHisto={{ setHisto }}
              allClients={allClients}
              setHistory={setHistory}
              jwt={jwt}
            />
          )
        ) : (
          <SendNft
            nft={id}
            SetSendPage={SetSendPage}
            popSnack={popSnack}
            addr={addr}
            from={histo.addr}
            jwt={jwt}
          />
        )) : (
        <Chat
          owner={histo.addr}
          clientXmtp={clientXmtp}
          allClients={allClients}
          setChat={setChat}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundColor: "#04152D",
            color: "white",
            fontWeight: "bold",
            fontSize: "1.5em",
          }}
        >
          {state == 0 ? t("TDelete") : t("TCallback")}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#04152D" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              backgroundColor: "#04152D",
              color: "white",
              fontWeight: "600",
              fontSize: "1.1em",
            }}
          >
            {state == 0 ? t("DeleteDesc") : t("CallBackDesc")}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "#04152D",
            color: "white",
            fontWeight: "bold",
            fontSize: "1.4em",
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: "#04152D",
              color: "white",
              fontWeight: "bold",
              fontSize: "0.8em",
              "&:hover": { backgroundColor: "#051e41" },
            }}
          >
            {t("Cancel")}
          </Button>
          {state == 0 ? (
            <Button
              onClick={DeleteNFT}
              sx={{
                color: "white",
                fontWeight: "bold",
                fontSize: "0.8em",
                "&:hover": { backgroundColor: "#051e41" },
              }}
              autoFocus
            >
              {t("Delete")}
            </Button>
          ) : (
            <Button
              onClick={GetBackNFT}
              sx={{
                color: "white",
                fontWeight: "bold",
                fontSize: "0.8em",
                "&:hover": { backgroundColor: "#051e41" },
              }}
              autoFocus
            >
              {t("Confirm")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {loader}
    </>
  );
};

interface DetailProps {
  nft: any;
  histo: { owner: string; data: string[]; id: string; addr: string };
  popSnack: any;
  jwt: string;
}

const DetailNft = ({ nft, histo, popSnack, jwt }: DetailProps) => {
  const [lost, setLost] = useState(nft.lost);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [queryParameters] = useSearchParams();
  const addr = queryParameters.get("col") || "none";
  const { t } = useTranslation("passportProduct");

  const productFound = () => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(
        `${backend}/nft/lost`,
        {
          coladdr: addr,
          id: nft.name.split("#")[1],
          lost: false,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: jwt,
          },
        }
      )
      .then((resp) => {
        setLost(false);
        nft.islost = false;
        console.log(resp);
        popSnack("NFT sent successfully", "success");
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        axios
          .post(
            `${backend}/client/getEmail`,
            {
              address: histo.addr,
            },
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API,
                Authorization: jwt,
              },
            }
          )
          .then((resp) => {
            console.log("email", resp);

            var templateParams = {
              to_name: histo.owner,
              from_name: "Hermès Customer Service",
              link_to: `http://13.36.226.45:3001/wallet?collec=${addr}&idNFT=${nft}`,
              to_email: resp.data,
            };

            emailjs
              .send(
                "NFT_Experience",
                "template_iywots5",
                templateParams,
                "dI1NV4PWOS0ci1eLZ"
              )
              .then(() => {
                console.log("email sent to :", resp.data);
              })
              .catch((err) => {
                console.log(err);
              });

            axios
              .post(
                `${backend}/client/mobileNotif`,
                {
                  address: histo.addr,
                },
                {
                  headers: {
                    "x-api-key": process.env.REACT_APP_API,
                    Authorization: jwt,
                  },
                }
              )
              .then(() => {
                console.log("mobile notification sent");
              })
              .catch((err) => {
                console.log(err);
              });
          });
      })
      .catch((err) => {
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack("Error occured while sending the nft", "error");
        console.log(err);
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "40vw",
          height: "60vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "40vw",
            height: "60vh",
            backgroundColor: "#1A223D",
            borderRadius: "10px",
            color: "white",
          }}
        >
          {lost ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "6vh",
                marginTop: "13px",
                backgroundColor: "red",
                color: "white",
                fontSize: "1.5em",
                fontWeight: "bold",
              }}
            >
              Lost
            </div>
          ) : (
            <></>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              flexDirection: "row",
              width: "35vw",
              height: "15vh",
              backgroundColor: "#1A223D",
              borderRadius: "10px",
              color: "white",
            }}
          >
            <h2 style={{ textAlign: "left" }}>{nft.name}</h2>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "row",
              width: "35vw",
              height: "25vh",
              backgroundColor: "#1A223D",
              borderRadius: "10px",
              color: "white",
            }}
          >
            <ImgNft image={nft.image} />
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                flexDirection: "column",
                marginLeft: "3vw",
              }}
            >
              <span style={{ fontSize: "1.3em", marginBottom: "5vh" }}>
                {t("Serial")} {nft.serial}
              </span>
              <span
                style={{
                  fontSize: "1.3em",
                  marginBottom: "5vh",
                  overflow: "hidden",
                  textAlign: "start",
                }}
              >
                {`${t("owner")} : ${histo.owner[0] == "0" && histo.owner[1] == "x"
                    ? histo.owner.slice(0, 20) + "..."
                    : histo.owner
                  }`}
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "row",
              width: "35vw",
              height: "15vh",
              backgroundColor: "#1A223D",
              borderRadius: "10px",
              color: "white",
              marginTop: "3vh",
            }}
          >
            {nft.description}
          </div>
        </div>
        {lost ? (
          <button
            className="lost-button"
            onClick={() => {
              productFound();
            }}
          >
            Product Found
          </button>
        ) : (
          <></>
        )}
      </div>
      {loader}
    </>
  );
};

interface UtilityProps {
  handleClick: any;
  type:
  | "certificat"
  | "chat"
  | "historic"
  | "privilege"
  | "callback"
  | "trash"
  | "send";
}

const UtilityButton = ({ handleClick, type }: UtilityProps) => {
  const icone =
    type == "chat"
      ? chat
      : type == "certificat"
        ? certif
        : type == "historic"
          ? histo
          : type == "privilege"
            ? priv
            : type == "callback"
              ? rappel
              : type == "trash"
                ? poubelle
                : send;
  return (
    <div
      onClick={handleClick}
      style={{
        width: "5vw",
        height: "5vw",
        backgroundColor: "#078BF9",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <img src={icone} style={{ width: "3vw" }} />
    </div>
  );
};

interface ImageProps {
  image: string;
}

export const ImgNft = ({ image }: ImageProps) => {
  const [img, setImg] = useState("");
  const [update, setUpdate] = useState(false);
  const { t } = useTranslation("passportProduct");

  useEffect(() => {
    if (img === "") {
      const imageUrl = axios
        .get(
          `${image}?pinataGatewayToken=aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              //'x-pinata-gateway-token' : 'aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191'
            },
            responseType: "arraybuffer",
          }
        )
        .then((res) => {
          //console.log(res)
          const binaryString = Array.from(new Uint8Array(res.data), (v) =>
            String.fromCharCode(v)
          ).join("");
          const theImage = btoa(binaryString);
          // console.log(theImage)
          setImg(theImage);
        })
        .catch((err) => {
          //console.log(err)
        })
        .finally(() => setUpdate(!update));
    }
  });

  return (
    <>
      {img === "" ? (
        <>{t("loading")}</>
      ) : (
        <img
          src={`data:image/png;base64,${img}`}
          style={{ maxHeight: "25vh", minHeight: "25vh", borderRadius: "10px" }}
        />
      )}
    </>
  );
};

interface HistoryProps {
  nft: any;
  popSnack: any;
  histo: { owner: string; data: string[]; id: string };
  setHisto: any;
  allClients: any;
  setHistory: any;
  jwt: string;
}

const History = ({
  nft,
  popSnack,
  histo,
  setHisto,
  allClients,
  setHistory,
  jwt,
}: HistoryProps) => {
  const [event, setEvent] = useState("");
  const [isHidden, setHidden] = useState(true);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [queryParameters] = useSearchParams();
  const addr = queryParameters.get("col") || "none";
  const id = nft.name.split("#")[1];
  const { t } = useTranslation("passportProduct");

  const createHistory = () => {
    console.log(typeof showLoader);
    if (typeof showLoader === "function") {
      console.log("show");
      showLoader();
    }
    console.log(process.env.REACT_APP_API);
    axios
      .post(
        `${backend}/nft/history/add`,
        {
          coladdr: addr,
          id: Number(id),
          data: event + ";" + startDate?.toString() + ";" + description,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: jwt,
          },
        }
      )
      .then((resp) => {
        console.log(resp);
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack(t("eventAdded"), "success");
      })
      .catch((err) => {
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack(t("errorMsg"), "error");

        console.log(err);
      });
  };

  console.log(histo);

  return (
    <>
      <div
        style={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          backgroundColor: "#04152D",
          minWidth: "80vw",
          height: "100vh",
        }}
      >
        <button
          style={{
            color: "#EF19D1",
            background: "none",
            border: "none",
            cursor: "pointer",
            textDecoration: "none",
            alignSelf: "start",
            marginTop: "15vh",
            marginBottom: "-15vh",
          }}
          onClick={() => {
            setHistory(false);
          }}
        >
          {t("goBack")}
        </button>
        <div
          className="history-box"
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "40vw",
            height: "60vh",
            backgroundColor: "#1A223D",
            borderRadius: "10px",
            flexDirection: "column",
            marginRight: "2vw",
            overflow: "scroll",
          }}
        >
          <h3
            style={{
              color: "white",
              fontSize: "1.7em",
              textAlign: "start",
              width: "90%",
              margin: "5% 0 3%",
            }}
          >
            {t("history")}
          </h3>
          {histo.data ? (
            histo?.data.map((elm, idx) => {
              return (
                <>
                  <Row2History
                    allClients={allClients}
                    date={elm.split(";")[1]}
                    comment={elm.split(";")[2]}
                    event={elm.split(";")[0]}
                    setHidden={setHidden}
                  />
                  {isHidden ? (
                    <></>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "100vw",
                        minHeight: "100vh",
                        backgroundColor: "#1A223D",
                        opacity: 0.2,
                        top: 0,
                        right: 0,
                      }}
                    >
                      <div
                        className="popup"
                        style={{
                          position: "absolute",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minWidth: "40vw",
                          minHeight: "40vh",
                          backgroundColor: "#1A223D",
                          opacity: 1,
                        }}
                      ></div>
                    </div>
                  )}
                </>
              );
            })
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "20vw",
            height: "60vh",
            backgroundColor: "#1A223D",
            borderRadius: "10px",
            flexDirection: "column",
            color: "white",
          }}
        >
          <h3
            style={{
              color: "white",
              fontSize: "1.5em",
              textAlign: "start",
              width: "90%",
              margin: "10% 0 0",
            }}
          >
            {/* {t("Aftersales")} */}
            Add an aftersale event
          </h3>
          {/* <TextField autoComplete='off' variant="outlined" label="Event"
                           style={{ marginInline: "30px", marginTop: "10%", minWidth: '90%'}}
                           className="txtfield" id="fullWidth" size="small" onChange={(e: any) => {
                    setEvent(e.target.value)
                }}/> */}
          <FormControl
            className="txtfield"
            style={{ minWidth: "90%", color: "white", marginTop: "10%" }}
          >
            <InputLabel sx={{ color: "white" }} id="demo-simple-select-label">
              {t("Event")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={event}
              label="Event type"
              onChange={(e: any) => {
                setEvent(e.target.value);
              }}
              sx={{ color: "white" }}
            >
              <MenuItem value={"Strap replacement"}>Strap replacement</MenuItem>
              <MenuItem value={"Replacement of watch head back"}>
                Replacement of watch head back
              </MenuItem>
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                bgcolor: "rgba(255, 255, 255, 0.9)",
                borderRadius: "5px",
                width: "90%",
                marginTop: "10%",
              }}
              value={startDate}
              onChange={(newvalue) => {
                setStartDate(newvalue);
              }}
            />
          </LocalizationProvider>
          <TextField
            autoComplete="off"
            variant="outlined"
            label={t("Comments")}
            multiline
            rows={6}
            style={{ margin: "10%", minWidth: "90%" }}
            inputProps={{ style: { color: "white" } }}
            className="txtfield"
            id="fullWidth"
            size="small"
            onChange={(e: any) => {
              setDescription(e.target.value);
            }}
          />
          <button
            style={{
              border: "none",
              backgroundColor: "#EF19D1",
              color: "white",
              minWidth: "90%",
              minHeight: "5vh",
              fontWeight: "bold",
              borderRadius: "100px",
              cursor: "pointer",
            }}
            onClick={() => {
              createHistory();
            }}
          >
            {t("createEvent")}
          </button>
        </div>
      </div>
      {loader}
    </>
  );
};

interface RowProps {
  date: string;
  comment: string;
  event: string;
  setHidden: any;
  allClients: any;
}

const RowHistory = ({ date, comment, event, setHidden }: RowProps) => {
  let dateConv = Date.parse(date);
  let dateFormat = new Date(dateConv);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "90%",
        minHeight: "7vh",
        color: "white",
        fontSize: "0.7em",
        backgroundColor: "#04152D",
        borderRadius: "5px",
        marginBottom: "3%",
      }}
    >
      <span
        style={{
          width: "%",
          wordBreak: "break-all",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textAlign: "left",
          marginLeft: "3%",
        }}
      >
        {event}
      </span>
      <span
        style={{
          width: "18%",
          wordBreak: "break-all",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {date !== "" ? dateFormat.toDateString() : ""}
      </span>
      <span
        style={{
          width: "50%",
          border: "none",
          background: "none",
          color: "white",
          fontSize: "0.9em",
          textAlign: "right",
          marginRight: "3%",
        }}
        onClick={() => { }}
      >
        {comment}
      </span>
    </div>
  );
};

const Row2History = ({
  date,
  comment,
  event,
  setHidden,
  allClients,
}: RowProps) => {
  let dateConv = Date.parse(date);
  let dateFormat = new Date(dateConv);
  let iconLigne = rouage;

  const matchAddr = (addr: string) => {
    let resAddr = "";
    allClients?.map((elm: any) => {
      console.log(elm.wallets[0], addr);
      if (elm.wallets[0]?.toLowerCase() == addr?.toLowerCase()) {
        console.log("EQUAL", elm.wallets[0], addr);
        resAddr = elm.name;
      }
    });
    if (resAddr == "") return addr;
    return resAddr;
  };

  let from = "";
  let to = "";
  if (event == "Mint") iconLigne = mint;
  else if (event == "Transfer") {
    iconLigne = transfert;
    from = matchAddr(comment.split(" Transfer to ")[0]);
    console.log("FROM ", from);
    to = matchAddr(comment.split(" Transfer to ")[1]);
    console.log("TO", comment.split(" Transfer to ")[1]);
    comment = from + " Transfer to " + to;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "90%",
        minHeight: "13vh",
        color: "white",
        fontSize: "0.7em",
        backgroundColor: "#04152D",
        borderRadius: "5px",
        marginBottom: "3%",
      }}
    >
      <span
        style={{
          width: "18%",
          wordBreak: "break-all",
          whiteSpace: "nowrap",
          overflow: "hidden",
          marginLeft: "3%",
        }}
      >
        {date !== "" ? dateFormat.toDateString() : ""}
      </span>
      <div
        style={{
          width: "12%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ minHeight: "13vh", borderLeft: "1px solid white" }}></div>
        <div
          style={{
            minWidth: "3vw",
            minHeight: "3vw",
            position: "absolute",
            borderRadius: "100px",
            border: "1px solid white",
            backgroundColor: "#04152D",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={iconLigne} style={{ maxWidth: "2vw" }} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          flexDirection: "column",
          width: "50%",
          marginRight: "3%",
          marginLeft: "3%",
          minHeight: "13vh",
        }}
      >
        <span
          style={{
            wordBreak: "break-all",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "left",
            marginTop: "2vh",
            fontWeight: "bold",
            fontSize: "1.2em",
          }}
        >
          {event}
        </span>
        <span
          style={{
            border: "none",
            background: "none",
            color: "white",
            fontSize: "0.9em",
            textAlign: "left",
            marginTop: "2vh",
          }}
          onClick={() => { }}
        >
          {comment}
        </span>
      </div>
    </div>
  );
};

export default Desktop;
