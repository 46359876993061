import { useEffect, useRef, useState } from "react";
import "../MobileApp.scss";
import { backend } from "src/App";
import axios from "axios";
import useFullPageLoader from "src/hooks/UseLoader";
import useEffectOnce from "src/hooks/UseEffectOnce";
import CollectionsWallet from "./CollectionsWallet";
import ProductWallet from "./ProductWallet";
import PoapWallet from "./PoapWallet";
import ByBearing from "src/assets/ByBearing.png";
import SignOut from "src/assets/sign-out.png";
import DeleteIcon from "src/assets/delete.png";
import MenuBurger from "src/assets/MenuBurger.png";
import Quit from "src/assets/quit.png";
import Qrcode from "src/assets/passport/qrcode.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import DetailNFT from "./DetailNFT/DetailNFT";
import CertificateNFT from "./DetailNFT/CertificateNFT";
import TransferNFT from "./DetailNFT/TransferNFT";
import Chat from "./DetailNFT/Chat";
import HistoryNFT from "./DetailNFT/HistoryNFT";
import Privilege from "./DetailNFT/Privilege";
import { useTranslation } from "react-i18next";
import { PopupQrCode } from "src/components/Popup/PopupQrcode";
import { Client } from "@xmtp/xmtp-js";
import { ethers } from "ethers";
import DetailPoap from "./DetailNFT/DetailPoap";

interface MobileWalletProps {
  allClients: any;
  popSnack: any;
  jwt: string;
}
export const MobileWallet = ({
  allClients,
  popSnack,
  jwt,
}: MobileWalletProps) => {
  const [page, setPage] = useState(1);
  const [ispoap, setIsPoap] = useState(false);
  const [collec, setCollec] = useState(undefined);
  const [offers, setOffers] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [openQrcode, setOpenQrcode] = useState(false);

  const [customerProd, setCustomerProd] = useState<any[]>([]);
  const [customerPoap, setCustomerPoap] = useState<any[]>([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [coladdr, setColAddr] = useState("");
  const [nft, setnft] = useState<{ name?: string } | undefined>(undefined);
  const [queryParameters] = useSearchParams();
  const addr = queryParameters.get("wallet") || "none";
  const { t } = useTranslation("mobileWallet");
  const [clientXmtp, setXmtp] = useState<Client>();
  const [file, setfile] = useState("");

  const navigate = useNavigate();

  let collections: [boolean, any][] = [];
  for (let index = 0; index < customerProd.length; index++) {
    collections.push([false, customerProd[index]]);
  }
  for (let index = 0; index < customerPoap.length; index++) {
    collections.push([true, customerPoap[index]]);
  }

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const fetchAll = async () => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    if (addr != undefined) {
      await timeout(2000);
      axios
        .post(
          `${backend}/alldatas/client`,
          { addr: addr },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API,
              Authorization: localStorage.getItem("token") || "",
            },
          }
        )
        .then((resp) => {
          setCustomerProd(resp.data.product);
          setCustomerPoap(resp.data.poap);
          setLoading(true);
          console.log("collections", resp.data);
          if (typeof hideLoader === "function") {
            hideLoader();
          }
        })
        .catch((err) => {
          console.log(err);
          if (typeof hideLoader === "function") {
            hideLoader();
          }
        });
    }
    //const wallet = undefined;
    const wallet = new ethers.Wallet(
      localStorage.getItem("privatekeyXmtp") || ""
    );
    if (wallet != undefined) {
      //const xmtp = undefined;
      console.log("wallet", wallet)
      const xmtp = await Client.create(wallet, { env: "production" });
      console.log("xmtpppp", xmtp);
      setXmtp(xmtp);
    }
  };

  const mintpoap = async () => {
    // const userPos = await getPosition();
    // const latitude = userPos.latitude;
    // const longitude = userPos.longitude;
    const latitude = 48.90543;
    const longitude = 2.24099;
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(
        `${backend}/poap/mint`,
        {
          address: localStorage.getItem("collec"),
          minter: addr,
          latitude: latitude,
          longitude: longitude,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        popSnack(t("poapMinted"), "success");
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        fetchAll();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.includes("event")) {
          popSnack(t("notInEvent"), "error");
        } else {
          popSnack(t("errorMintingPoap"), "error");
        }
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        fetchAll();
      });
  };

  const sendnft = () => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(
        `${backend}/nft/send`,
        {
          contractAddress: localStorage.getItem("collec"),
          wallet: addr,
          tokenId: localStorage.getItem("idNft"),
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        console.log("nft sent to", addr);
        popSnack(t("nftMinted"), "success");
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        fetchAll();
      });
  };

  useEffectOnce(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const privateXmtp = queryParameters.get("pK");
    if (privateXmtp != undefined && privateXmtp != "") {
      localStorage.setItem("privatekeyXmtp", privateXmtp);
    }
    const tok = queryParameters.get("token");
    localStorage.setItem("token", tok || "");

    // if (window?.navigator?.platform == "iPhone") {
    //   window.location.assign("deeplink://connected?jwt=" + jwt);
    // }
    console.log("useeffecthook");
    if (localStorage.getItem("idNft") != undefined) {
      sendnft();
    } else if (localStorage.getItem("collec") != undefined) {
      mintpoap();
    } else {
      fetchAll();
    }
  });

  // let titles: string[] = [
  //   t("myCollections"),
  //   t("myNfts"),
  //   (nft && nft.name) || t("NFT Details"),
  //   t("Certificates"),
  //   t("Chat"),
  //   t("History"),
  //   t("Transfer"),
  //   t("Privilege"),
  //   t(""),
  // ];

  let titles: string[] = [
    "",
    t("myNfts"),
    (nft && nft.name) || t("nftDetails"),
    ispoap ? t("Document") : t("Certificate"),
    t("Chat"),
    t("History"),
    t("Transfer"),
    t("Privilege"),
    ispoap ? t("Document") : t("Certificate"),
  ];
  let current_title = titles[page - 1];

  const deleteClient = async () => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    if (addr !== undefined) {
      await timeout(2000);
      axios
        .post(
          `${backend}/client/delete`,
          { address: addr },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API,
              Authorization: localStorage.getItem("token") || "",
              // 'Content-Type': 'application/json' // au besoin
            },
          }
        )
        .then((resp) => {
          // Do something with the response if needed
          if (typeof hideLoader === "function") {
            hideLoader();
          }
        })
        .catch((err) => {
          console.log(err);
          if (typeof hideLoader === "function") {
            hideLoader();
          }
        });
    }
  };

  const Slider = ({
    isOpen,
    onClose,
  }: {
    isOpen: boolean;
    onClose: () => void;
  }) => {
    const handleLogout = () => {
      // perform logout and account deletion here
      navigate("/wallet");
    };

    const handledeleteAccount = async () => {
      await deleteClient();
      navigate("/wallet");
    };

    return (
      <div
        style={{
          position: "fixed",
          right: 0,
          top: 0,
          width: "35vw", // change this as needed
          height: "100vh",
          background: "#1C223B", // change this as needed
          transform: isOpen ? "translateX(0)" : "translateX(100%)",
          transition: "transform 0.3s ease-in-out",
          zIndex: 1000,
          color: "#ffffff", // Assuming you want white color for the text
          padding: "10px", // Providing some space for the contents
        }}
      >
        <button
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            background: "transparent",
            border: "none",
          }}
          onClick={onClose}
        >
          <img src={Quit} alt="Close" style={{ width: "20px" }} />
        </button>
        <div style={{ marginTop: "50%" }}>
          {" "}
          {/* Move content to the center */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <p style={{ marginRight: "10px", fontSize: "14px" }}>
                {t("Qrcode")}
              </p>
              <button
                style={{ background: "transparent", border: "none" }}
                onClick={() => {
                  setOpenQrcode(true);
                }}
              >
                <img src={Qrcode} alt="Sign Out" style={{ width: "20px" }} />
              </button>
              <PopupQrCode
                open={openQrcode}
                setOpen={setOpenQrcode}
                value={addr}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <p style={{ marginRight: "0px", fontSize: "14px" }}>
                {t("signout")}
              </p>
              <button
                style={{ background: "transparent", border: "none" }}
                onClick={handleLogout}
              >
                <img src={SignOut} alt="Sign Out" style={{ width: "20px" }} />
              </button>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ marginRight: "10px", fontSize: "14px" }}>
                {t("deleteAcc")}
              </p>
              <button
                style={{ background: "transparent", border: "none" }}
                onClick={handledeleteAccount}
              >
                <img
                  src={DeleteIcon}
                  alt="Delete Account"
                  style={{ width: "20px" }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const signout = () => {
    navigate("/wallet");
  };

  return (
    <>
      <div className="wallet-background">
        <div>
          <div className="wallet-header">
            <div className="wallet-back">
              {page <= 1 ? (
                <></>
              ) : (
                <button
                  className="wallet-back-btn"
                  onClick={() => {
                    if (page == 9) {
                      setPage(4);
                    } else if (page > 3) {
                      setPage(3);
                    } else if (page === 3) {
                      setPage(1);
                    }else {
                      setPage(page - 1);
                    }
                  }}
                >
                  <span className="wallet-back-txt">&#60;&#32;{t("Back")}</span>
                </button>
              )}
            </div>
            <div
              className="wallet-title"
              style={page == 3 ? { fontSize: "26px" } : {}}
            >
              {current_title}
            </div>
            <div>
              <button
                className="wallet-btn-signout"
                onClick={() => setSliderOpen(true)}
              >
                <img
                  src={MenuBurger}
                  alt="Menu"
                  className="wallet-img-signout"
                  style={{ width: "20px" }}
                />
              </button>
            </div>
          </div>
          <div className="wallet-content">
            {!isLoading ? (
              <></>
            ) : page === 1 ? (
              <CollectionsWallet
                setPage={setPage}
                collections={collections}
                setIsPoap={setIsPoap}
                setCollec={setCollec}
                setOffers={setOffers}
                setColAddr={setColAddr}
              />
            ) : page === 2 ? (
              <ProductWallet
                setPage={setPage}
                collec={collec}
                page={page}
                setnft={setnft}
                ispoap={ispoap}
              />
            ) : page === 3 && !ispoap ? (
              <DetailNFT
                jwt={jwt}
                collec={collec}
                nft={nft}
                setPage={setPage}
                popSnack={popSnack}
                coladdr={coladdr}
                setfile={setfile}
              />
            ) : page === 3 && ispoap ? (
              <DetailPoap
                collec={collec}
                nft={nft}
                setPage={setPage}
                setfile={setfile}
              />
            ) : page === 4 || page == 9 ? (
              <CertificateNFT file={file} page={page} setpage={setPage} />
            ) : page === 5 ? (
              <Chat clientXmtp={clientXmtp} />
            ) : page == 6 ? (
              <HistoryNFT
                jwt={jwt}
                nft={nft}
                coladdr={coladdr}
                allClients={allClients}
              />
            ) : page == 7 ? (
              <TransferNFT
                nft={nft}
                coladdr={coladdr}
                popSnack={popSnack}
                jwt={jwt}
              />
            ) : (
              <Privilege collec={collec} offers={offers} />
            )}
          </div>
          <div className="footer">
            <div style={{ height: "5%" }}>
              <div>
                <img src={ByBearing} className="wallet-img-bpt" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Slider isOpen={isSliderOpen} onClose={() => setSliderOpen(false)} />
      {loader}
    </>
  );
};

export default MobileWallet;
