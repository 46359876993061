import React, { useEffect, useState } from "react";
import Card from "../CollectionCard/ChooseCard";
import CollectionHeader from "../StepTitle/StepTitle";

import "../ChooseCollection/ChooseCollection.css";
import { useTranslation } from "react-i18next";
import Popup from "../Popup/Popup";
import Broadcast from "../Broadcast/Broadcast";

interface ChooseColBroadcastProps {
  nfts: any[];
  poap: any;
  popSnack: any;
  jwt: string;
  currWallet: any;
  clientXmtp: any;
}

export const ChooseColBroadcast = ({
  popSnack,
  nfts,
  poap,
  jwt,
  currWallet,
  clientXmtp,
}: ChooseColBroadcastProps) => {
  const [openPopup, setPopup] = useState(false);
  const [isOpened, setOpened] = useState(false);

  const launchPopup = () => {
    setPopup(true);
  };
  console.log(currWallet);
  useEffect(() => {
    if (!isOpened && currWallet.name == "") {
      launchPopup();
      setOpened(true);
    }
  });

  return (
    <>
      {" "}
      <Popup
        open={openPopup}
        setOpen={setPopup}
        title={"Please select a wallet"}
        content={"Please select a wallet in your side bar to continue"}
      />
      {currWallet.name !== "" ? (
        <div className="choose-background">
          <Slider
            vaultId={currWallet.vault}
            jwt={jwt}
            popSnack={popSnack}
            nfts={nfts}
            poap={poap}
            currWallet={currWallet}
            clientXmtp={clientXmtp}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

interface SliderProps {
  nfts: any[];
  poap: any;
  popSnack: any;
  jwt: string;
  vaultId: string;
  currWallet: string;
  clientXmtp: any;
}

export const Slider = ({
  popSnack,
  nfts,
  poap,
  jwt,
  vaultId,
  currWallet,
  clientXmtp,
}: SliderProps) => {
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [current, setCurrent] = useState<any>();
  const { t } = useTranslation("chat");

  if (poap.length >= 1 && isLoading) {
    setLoading(false);
  }

  let numberOfPages = Math.ceil(poap.length - 1);
  let circles = [];

  for (let index = 0; index < numberOfPages; index++) {
    if (index + 1 === currentPage) {
      circles.push(<Circle fill={false} key={index} />);
    } else {
      circles.push(<Circle fill key={index} />);
    }
  }

  let collections: [boolean, any][] = [];
  for (let index = 0; index < poap.length; index++) {
    collections.push([true, poap[index]]);
  }


  return (
    <>
      {current == undefined ? (
        <div className="slider-background">
          {poap.length < 1 && poap.length < 1 ? (
            <>Loading</>
          ) : (
            <>
              <div className="slider-head-2">
                <CollectionHeader
                  page={1}
                  total={2}
                  title={t("BroadcastTitle")}
                  description={t("BroadcastDesc")}
                />
                <div>
                  <button
                    className="slider-buttons"
                    style={{ marginRight: "1vw" }}
                    onClick={() => {
                      if (currentPage - 1 >= 1) setCurrentPage(currentPage - 1);
                    }}
                  >
                    {"<"}
                  </button>
                  <button
                    className="slider-buttons"
                    onClick={() => {
                      if (currentPage + 1 <= numberOfPages)
                        setCurrentPage(currentPage + 1);
                    }}
                  >
                    {">"}
                  </button>
                </div>
              </div>
              <div className="slider-middle">
                {!isLoading ? (
                  collections.map((elm, idx) => {
                    if (Math.ceil((idx + 1) / 2) === currentPage) {
                      if (!elm[0]) {
                        return (
                          <CardWindow
                            idx={idx}
                            elm={elm[1]}
                            setCurrent={setCurrent}
                            ispoap={false}
                            currentPage={currentPage}
                          />
                        );
                      } else {
                        return (
                          <CardWindow
                            idx={idx}
                            setCurrent={setCurrent}
                            elm={elm[1]}
                            ispoap={true}
                            currentPage={currentPage}
                          />
                        );
                      }
                    }
                  })
                ) : (
                  <>{t("loading")}</>
                )}
              </div>
              <div className="slider-foot">{circles}</div>
            </>
          )}
        </div>
      ) : (
        <Broadcast
          vaultId={vaultId}
          jwt={jwt}
          popSnack={popSnack}
          setCurrent={setCurrent}
          collection={current}
          currWallet={currWallet}
          clientXmtp={clientXmtp}
        />
      )}
    </>
  );
};

interface CardWindowProps {
  idx: number;
  setCurrent: any;
  elm: any;
  currentPage: number;
  ispoap: boolean;
}

const CardWindow = ({
  idx,
  setCurrent,
  elm,
  currentPage,
  ispoap,
}: CardWindowProps) => {
  console.log("i am cardwindow")
  return (
    <>
      {Math.ceil((idx + 1) / 2) === currentPage ? (
        <button
          onClick={() => {
            setCurrent(elm);
          }}
          style={{ border: "none", background: "none" }}
        >
          {<Card key={idx} collection={elm} idx={idx} isPoa={ispoap} />}
        </button>
      ) : (
        <></>
      )}
    </>
  );
};

interface CircleProps {
  fill: boolean;
}

const Circle = ({ fill }: CircleProps) => {
  return <div className={fill ? "circle-filled" : "circle-not-filled"} />;
};

export default ChooseColBroadcast;
