import { useEffect, useState } from "react";
import "../../MobileApp.scss";
import SendIcon from "@mui/icons-material/Send";

interface ChatProps {
  clientXmtp: any;
}

export const Chat = ({ clientXmtp }: ChatProps) => {
  const [conv, setConv] = useState<any>();
  const [msgSend, setMsgSend] = useState("");
  const [msgSent, setMsgSent] = useState(false);
  const [msgs, setMsgs] = useState<any[]>([]);
  const [msgResp, setMsgResp] = useState<any[]>([]);
  let admWallet = process.env.REACT_APP_XMTPADDR || "";
  const pubwallet = clientXmtp.address;
  const [msgRecvQueue, setMsgRecvQueue] = useState<any[]>([]);

  const sendMessage = async () => {
    setMsgSent(true);
    await conv.send(msgSend);
    setMsgSend("");
  };

  useEffect(() => {
    const stream = async () => {
      if (conv != undefined) {
        for await (const message of await conv.streamMessages()) {
          if (message.senderAddress === clientXmtp.address) {
            continue;
          }
          let msg = {
            senderAddress: message.senderAddress,
            content: message.content,
          };
          setMsgRecvQueue((prevQueue) => [...prevQueue, msg]);
        }
      }
    };
    stream();
  }, [conv]);

  useEffect(() => {
    if (msgRecvQueue.length > 0) {
      let newMsg = msgRecvQueue[0];
      let exist = false;
      for (let i = 0; i < msgResp.length; i++) {
        if (msgResp[i].content == newMsg.content) {
          exist = true;
          break;
        }
      }
      if (exist == false) {
        setMsgResp((prevNewMessages) => [...prevNewMessages, newMsg]);
      }
      setMsgRecvQueue((prevQueue) => prevQueue.slice(1));
    }
  }, [msgRecvQueue]);

  useEffect(() => {
    const getopttt = async () => {
      if (conv != undefined) {
        const messagesInConversation = await conv.messages();
        setMsgs(messagesInConversation);
        console.log(messagesInConversation);
      }
    };
    getopttt();
  }, [conv]);

  useEffect(() => {
    const getconv = async () => {
      const isOnNetwork = await clientXmtp.canMessage(admWallet, {
        env: "production",
      });
      console.log(isOnNetwork);
      const allConversations = await clientXmtp.conversations.list();
      let exist = false;
      for (const conversation of allConversations) {
        if (conversation.peerAddress === admWallet) {
          exist = true;
          setConv(conversation);
        }
        console.log(`Saying GM to ${conversation.peerAddress} and ${admWallet}`);
      }
      if (exist == false) {
        const conversation = await clientXmtp.conversations.newConversation(
          admWallet
        );
        setConv(conversation);
      }
      setMsgResp([]);
      setMsgSent(false);
    };
    getconv();
  }, [msgSent]);

  return (
    <div className="xmpt-conv-bg">
      <div className="xmtp-conv">
        {msgs != undefined ? (
          msgs.map((msg) => {
            return (
              <>
                {msg.senderAddress == pubwallet ? (
                  <div
                    className="xmtp-msg"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div className="xtmp-msg-self">{msg.content}</div>
                  </div>
                ) : (
                  <div
                    className="xmtp-msg"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <div className="xtmp-msg-other">{msg.content}</div>
                  </div>
                )}
              </>
            );
          })
        ) : (
          <></>
        )}
        {msgResp != undefined ? (
          msgResp.map((msg) => {
            return (
              <div
                className="xmtp-msg"
                style={{ justifyContent: "flex-start" }}
              >
                <div className="xtmp-msg-other">{msg.content}</div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <div className="xmtp-msg-sender">
        <input
          value={msgSend}
          onChange={(e) => {
            setMsgSend(e.target.value);
          }}
          className="xmtp-input"
        />
        <button
          className="xmtp-sendmsg"
          onClick={() => {
            sendMessage();
          }}
        >
          <SendIcon style={{ color: "#EF19D1" }} />
        </button>
      </div>
    </div>
  );
};

export default Chat;
