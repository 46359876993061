import { useTranslation } from "react-i18next";
import "../MobileApp.scss";
import CardWallet from "./CardWallet";

interface CollectionsWalletProps {
  setPage: any;
  collections: any;
  setIsPoap: any;
  setCollec: any;
  setOffers: any;
  setColAddr: any;
}

export const CollectionsWallet = ({
  setPage,
  collections,
  setIsPoap,
  setCollec,
  setOffers,
  setColAddr,
}: CollectionsWalletProps) => {
  const { t } = useTranslation("mobileWallet");
  
  const setProduct = (elm: any) => {
    setIsPoap(false);
    setCollec(elm);
    setColAddr(elm.address);
    setOffers(elm.offers);
    setPage(2);
  };

  const setPoap = (elm: any) => {
    setIsPoap(true);
    setColAddr(elm.address);
    setCollec(elm);
    setOffers(elm.offers);
    setPage(2);
  };

  return (
    <>
      <div>
        <div className="wallet-title-city">
          {t("titlePass")}
        </div>
        <div className="wallet-card-middle">
          {collections.map((elm: any[], idx: number) => {
            if (!elm[0]) {
              return (
                <div key={idx}>
                  <button
                    onClick={() => setProduct(elm[1])}
                    style={{ border: "none", background: "none" }}
                  >
                    <CardWallet
                      key={idx}
                      page={1}
                      collection={elm[1]}
                      isPoa={false}
                      fromPoap={false}
                      image={""}
                    />
                  </button>
                </div>
              );
            } else {
              return (
                <div key="{elm}">
                  <button
                    onClick={() => setPoap(elm[1])}
                    style={{ border: "none", background: "none" }}
                  >
                    <CardWallet
                      key={idx}
                      page={1}
                      collection={elm[1]}
                      isPoa={true}
                      fromPoap={false}
                      image={""}
                    />
                  </button>
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default CollectionsWallet;
