import React, { useState } from "react";
import CustomerTable from "./TableV2";
import StepTitle from "../StepTitle/StepTitle";
import CustomerDetails from "./CustomersDetails";

import "./Customers.css";
import { useTranslation } from "react-i18next";

interface CustomerProps {
  jwt: string;
}

export const Customers = ({ jwt }: CustomerProps) => {
  const [selected, setSelected] = useState(-1);
  const [customer, setCustomer] = useState<{
    email: string;
    id: number;
    name: string;
    wallets: string[];
  }>();
  const { t } = useTranslation("customers");

  return (
    <div className="customers-background">
      <div style={{ marginTop: "25vh" }}></div>

      {customer == undefined ? (
        <>
          <div className="create-head-3">
            <h2 style={{ color: "#078BF9" }}>{t("relationship")}</h2>
          </div>
          <div className="customer-table">
            <CustomerTable
              jwt={jwt}
              setUser={setCustomer}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </>
      ) : (
        <>
          <div className="create-head-3">
            <h2 style={{ color: "#078BF9" }}>{t("clientview")}</h2>
          </div>
          <div className="customer-table">
            <CustomerDetails
              jwt={jwt}
              customer={customer}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Customers;
