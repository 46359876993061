import { useState } from "react";
import Card from "../CollectionCard/Card";
import CreateCard from "../CollectionCard/CreateCard";
import { useNavigate } from "react-router-dom";
import "./Slider.css";
import { useTranslation } from "react-i18next";

interface Event {
  eventName: string;
  date: string;
  description: string;
  address: string;
  imgs: string[];
  uri: string;
}

function removeEventByName(events: Event[], eventNameToRemove: string): Event[] {
  return events.filter(event => event.eventName !== eventNameToRemove);
}

interface SliderProps {
  nfts: any[];
  poas: any[];
}

export const Slider = ({ nfts, poas }: SliderProps) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();

  poas = removeEventByName(poas, "11")

  const numberOfPages = Math.ceil((nfts.length + poas.length) / 2);

  console.log("poas", poas)

  if (nfts.length + poas.length > 0 && isLoading) {
    setLoading(false);
  }

  let circles = [];
  for (let index = 0; index < numberOfPages; index++) {
    if (index + 1 === currentPage) {
      circles.push(<Circle fill={false} />);
    } else {
      circles.push(<Circle fill />);
    }
  }

  let collections: [boolean, any][] = [];
  for (let index = 0; index < nfts.length; index++) {
    collections.push([false, nfts[index]]);
  }
  for (let index = 0; index < poas.length; index++) {
    collections.push([true, poas[index]]);
  }

  return (
    <div className="slider-background">
      {isLoading ? (
        <div
          style={{ color: "white", fontWeight: "bold", marginBottom: "10vh" }}
        >
          {t("load")}
        </div>
      ) : !isLoading && collections.length === 0 ? (
        <div
          style={{ color: "white", fontWeight: "bold", marginBottom: "10vh" }}
        >
          {t("noCollection")}
        </div>
      ) : (
        <>
          <div className="slider-head">
            <button
              className="slider-buttons"
              style={{ marginRight: "1vw" }}
              onClick={() => {
                if (currentPage - 1 >= 1) setCurrentPage(currentPage - 1);
              }}
            >
              {"<"}
            </button>
            <button
              className="slider-buttons"
              onClick={() => {
                if (currentPage + 1 <= numberOfPages)
                  setCurrentPage(currentPage + 1);
              }}
            >
              {">"}
            </button>
          </div>
          <div className="slider-middle">
            <CreateCard />
            {collections.map((elm, idx) => {
              if (Math.ceil((idx + 1) / 2) === currentPage) {
                if (!elm[0]) {
                  return (
                    <button
                      key={idx}
                      style={{ border: "none", background: "none" }}
                      onClick={() => {
                        navigate(
                          `/collection/admin?col=${elm[1].address}&nbElms=${elm[1].nbElms}/`
                        );
                      }}
                    >
                      <Card
                        key={idx}
                        collection={elm[1]}
                        idx={idx}
                        isPoa={false}
                      />
                    </button>
                  );
                } else {
                  return (
                    <button
                      key={idx}
                      style={{ border: "none", background: "none" }}
                      onClick={() => {
                        navigate(`/collection/poap/${elm[1].address}`);
                      }}
                    >
                      <Card
                        key={idx}
                        collection={elm[1]}
                        idx={idx}
                        isPoa={true}
                      />
                    </button>
                  );
                }
              } else {
                return null;
              }
            })}
          </div>
          <div className="slider-foot">{circles}</div>
        </>
      )}
    </div>
  );
};

interface CircleProps {
  fill: boolean;
}

const Circle = ({ fill }: CircleProps) => {
  return <div className={fill ? "circle-filled" : "circle-not-filled"} />;
};

export default Slider;
