import "../../MobileApp.scss";
import CardWallet from "../CardWallet";
import histo from "src/assets/passport/historic.png";
import certif from "src/assets/passport/certif.png";
import priv from "src/assets/passport/privilege.png";
import chat from "src/assets/passport/chat.png";
import send from "src/assets/passport/send.png";
import passport from "src/assets/passport.png";
import lost from "src/assets/passport/lost.png";
import found from "src/assets/passport/found.png";
import useFullPageLoader from "src/hooks/UseLoader";
import axios from "axios";
import { backend } from "src/App";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useEffectOnce from "src/hooks/UseEffectOnce";

interface DetailNFTProps {
  collec: any;
  nft: any;
  setPage: any;
  popSnack: any;
  coladdr: string;
  jwt: string;
  setfile: any;
}

export const DetailNFT = ({
  collec,
  nft,
  setPage,
  popSnack,
  coladdr,
  jwt,
  setfile,
}: DetailNFTProps) => {
  console.log("nft", nft);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { t } = useTranslation("mobileWallet");
  const [open, setOpen] = useState(false);
  
  useEffectOnce(() => {
    const urlsplit = nft.certif.split("/");
    const docName = urlsplit[urlsplit.length - 1] || "7a7634bf-cd5c-4ba2-97c4-9daca4116ef9.pdf"
    setfile([docName])
  });

  const openPassport = () => {
    window.open(`/passport/product`, "_blank");
  };

  const productLost = (loststatus: boolean) => {
    setOpen(false);
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(
        `${backend}/nft/lost`,
        {
          coladdr: coladdr,
          id: nft.name.split("#")[1],
          lost: loststatus,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: jwt,
          },
        }
      )
      .then((resp) => {
        nft.lost = loststatus;
        console.log(resp);
        popSnack(t("notified"), "success");
        if (typeof hideLoader === "function") {
          hideLoader();
        }
      })
      .catch((err) => {
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack(t("errorNotified"), "error");
        console.log(err);
      });
  };

  return (
    <>
      {nft.lost ? <div className="nft-lost">{t("lost")}</div> : <></>}
      <CardWallet
        page={3}
        collection={collec}
        fromPoap={true}
        isPoa={false}
        image={nft.image}
      />
      <div
        className="nft-btn-col"
        style={nft.lost ? { height: "38vh", marginTop: "0px" } : {}}
      >
        <div className="nft-btn-row">
          <UtilityButton
            handleClick={() => {
              openPassport();
            }}
            type={t("Passport")}
          />
          <UtilityButton
            handleClick={() => {
              setPage(4);
            }}
            type={t("Certificate")}
          />
          <UtilityButton
            handleClick={() => {
              setPage(8);
            }}
            type={t("Privilege")}
          />
        </div>
        <div className="nft-btn-row">
          <UtilityButton
            handleClick={() => {
              setPage(5);
            }}
            type={t("Chat")}
          />
          <UtilityButton
            handleClick={() => {
              setPage(6);
            }}
            type={t("History")}
          />
          <UtilityButton
            handleClick={() => {
              setPage(7);
            }}
            type={t("transfer")}
          />
        </div>
        <div className="nft-btn-single">
          {nft.lost ? (
            <UtilityButton
              handleClick={() => {
                setOpen(true);
              }}
              type={t("Found")}
            />
          ) : (
            <UtilityButton
              handleClick={() => {
                setOpen(true);
              }}
              type={t("lost")}
            />
          )}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundColor: "#04152D",
            color: "white",
            fontWeight: "bold",
            fontSize: "1.5em",
          }}
        >
          {!nft.lost ? (
            <span>{t("lostproduct")}</span>
          ) : (
            <span>{t("Foundproduct")}</span>
          )}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#04152D" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              backgroundColor: "#04152D",
              color: "white",
              fontWeight: "600",
              fontSize: "1.1em",
            }}
          >
            {!nft.lost ? (
              <span>{t("questionLost")}</span>
            ) : (
              <span>{t("questionFound")}</span>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "#04152D",
            color: "white",
            fontWeight: "bold",
            fontSize: "1.4em",
          }}
        >
          <Button
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              backgroundColor: "#04152D",
              color: "white",
              fontWeight: "bold",
              fontSize: "0.8em",
              "&:hover": { backgroundColor: "#051e41" },
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              productLost(!nft.lost);
            }}
            sx={{
              color: "white",
              fontWeight: "bold",
              fontSize: "0.8em",
              "&:hover": { backgroundColor: "#051e41" },
            }}
            autoFocus
          >
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      {loader}
    </>
  );
};

interface UtilityProps {
  handleClick: any;
  type: any;
}

const UtilityButton = ({ handleClick, type }: UtilityProps) => {
  const { t } = useTranslation("mobileWallet");
  const icone =
    type == t("Chat")
      ? chat
      : type == t("Certificate")
      ? certif
      : type == t("History")
      ? histo
      : type == t("transfer")
      ? send
      : type == t("Passport")
      ? passport
      : type == t("lost")
      ? lost
      : type == t("Found")
      ? found
      : priv;
  return (
    <div className="nft-btn">
      <div onClick={handleClick} className="nft-btn-content">
        <img src={icone} style={{ width: "10vw" }} />
      </div>
      {type}
    </div>
  );
};

export default DetailNFT;
