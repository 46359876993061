import { TextField } from "@mui/material";
import "../../MobileApp.scss";
import { useContext, useState } from "react";
import { PopupChoice as Popup } from "src/components/Popup/Popup";
import useFullPageLoader from "src/hooks/UseLoader";
import axios from "axios";
import { backend, GlobalStateContext } from "src/App";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface TransferNFTProps {
  nft: any;
  coladdr: string;
  popSnack: any;
  jwt: string;
}

export const TransferNFT = ({
  nft,
  coladdr,
  popSnack,
  jwt,
}: TransferNFTProps) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [queryParameters] = useSearchParams();
  const ownerAddr = queryParameters.get("wallet") || "none";
  const { t } = useTranslation("mobileWallet");
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar } = context;

  const titlepopup = "Transfer NFT";
  const descriptionpopup =
    "Are you sure you want to transfer this NFT to " + email + "?";

  const handleClick = () => {
    setOpen(true);
  };

  const sendNft = () => {
    setOpen(false);
    if (typeof showLoader === "function") {
      showLoader();
    }
    console.log("nftid", nft.id)

    axios
      .post(
        `${backend}/transferauth/add`,
        {
          collAddr: coladdr,
          idToken: nft.name.split("#")[1],
          walletFrom: ownerAddr,
          emailTo: email,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            Authorization: jwt,
            vault: globalVar,
          },
        }
      )
      .then((resp) => {
        console.log(resp);
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack("Transfer Request successfully registered", "success");
      })
      .catch((err) => {
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack(
          "Error occured while registering the request transfer of the nft",
          "error"
        );
        console.log(err);
      });
  };

  return (
    <>
      <div className="transfer">
        <TextField
          className="send-txtfield"
          autoComplete="off"
          variant="outlined"
          label={t("emailAddress")}
          style={{ minWidth: "85vw", marginTop: "0.5vh", marginInline: "3vw" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="nft-btn">
          <div
            onClick={handleClick}
            className="nft-btn-content"
            style={{ width: "20vw", height: "7vh" }}
          >
            <span>{t("transfer")}</span>
          </div>
        </div>
        <Popup
          open={open}
          setOpen={setOpen}
          titletext={titlepopup}
          descriptiontext={descriptionpopup}
          Action={sendNft}
        />
      </div>
      {loader}
    </>
  );
};

export default TransferNFT;
