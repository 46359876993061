import { TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { backend } from "src/App";
import lock from "src/assets/passport/lockw.png";
import payment from "src/assets/passport/payment.png";

interface PaymentProps {
  popSnack: any;
  money: number;
  actionConnect: any;
  collec: string;
}

export const Payment = ({ popSnack, money, actionConnect, collec }: PaymentProps) => {
  const [code, setCode] = useState("");
  const { t } = useTranslation("mobileConnection");

  const Pay = () => {
    actionConnect();
  };

  const Redeem = () => {
    axios
      .post(
        `${backend}/poap/activatecode`,
        { code: code, addr: collec },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API
          },
        }
      )
      .then((resp) => {
        console.log(resp.data)
        if (resp.data === 0) {
          popSnack(t("codeRedeem"), "success");
          actionConnect();
        } else if (resp.data === 1){
          popSnack(t("codeExist"), "error");
        } else if (resp.data === 2){
          popSnack(t("codeCollec"), "error");
        } else if (resp.data === 3){
          popSnack(t("codeUse"), "error");
        } else {
          popSnack(t("errorCode"), "error");
        }
      })
      .catch((err) => {
        console.log(err);
        popSnack(t("errorCode"), "error");
      });
  }

  return (
    <div className="wallet-user">
      <div style={{overflow: "scroll"}}>
      <div className="wallet-user-txt">{t("payment")}</div>
      <div className="wallet-user-form">
        <TextField
          className="send-txtfield"
          autoComplete="cc-name"
          variant="outlined"
          label={t("activationCode")}
          style={{ minWidth: "22vw", marginTop: "3vh", marginInline: "3vw" }}
          value={code} 
          onChange={(e: any) => {
            setCode(e.target.value)
          }}
        />
        <div style={{ alignItems: "center", justifyContent: "center" }}>
          <button
            className="wallet-user-btn-payment"
            style={{background: "#3883ec"}}
            onClick={() => {
              Redeem();
            }}
          >
            {t("redeem")}
          </button>
        </div>
      </div>
      <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "3vh",
            marginBlock: "0.5vh"
          }}>
          <div
            style={{
              width: "38%",
              border: "0.5px solid white",
              marginRight: "5px"
            }}
          />
          OU
          <div
            style={{
              width: "38%",
              border: "0.5px solid white",
              marginLeft: "5px"
            }}
          />
        </div>
      <div className="wallet-user-form">
        <div className="wallet-payment">
          <img src={payment} alt="" style={{ width: "80%" }} />
        </div>
        <TextField
          className="send-txtfield"
          autoComplete="cc-name"
          variant="outlined"
          label={t("OwnerName")}
          style={{ minWidth: "22vw", marginTop: "3vh", marginInline: "3vw" }}
        />
        <TextField
          className="send-txtfield"
          autoComplete="cc-number"
          variant="outlined"
          label={t("CreditCard")}
          style={{ minWidth: "22vw", marginTop: "3vh", marginInline: "3vw" }}
        />
        <div>
          <TextField
            className="send-txtfield"
            autoComplete="cc-exp"
            variant="outlined"
            label="MM / YY"
            style={{
              minWidth: "20vw",
              maxWidth: "40vw",
              marginTop: "3vh",
              marginRight: "2vw",
            }}
          />
          <TextField
            className="send-txtfield"
            autoComplete="cc-csc"
            variant="outlined"
            label="CVV"
            style={{
              minWidth: "20vw",
              maxWidth: "40vw",
              marginTop: "3vh",
              marginLeft: "2vw",
            }}
          />
        </div>
        <div style={{ alignItems: "center", justifyContent: "center" }}>
          <button
            className="wallet-user-btn-payment"
            onClick={() => {
              Pay();
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: "40%" }}>
                <img
                  src={lock}
                  alt=""
                  style={{ width: "17%", marginLeft: "25px" }}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {t("pay")} {money}€
              </div>
            </div>
          </button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Payment;
