import "../../MobileApp.scss";
import CardWallet from "../CardWallet";
import certif from "src/assets/passport/certif.png";
import priv from "src/assets/passport/privilege.png";
import chat from "src/assets/passport/chat.png";
import { useTranslation } from "react-i18next";
import useEffectOnce from "src/hooks/UseEffectOnce";
import QRCode from "react-qr-code";

interface DetailPoapProps {
  collec: any;
  nft: any;
  setPage: any;
  setfile: any;
}

export const DetailPoap = ({
  collec,
  nft,
  setPage,
  setfile,
}: DetailPoapProps) => {
  const { t } = useTranslation("mobileWallet");
  console.log("nft", nft);

  useEffectOnce(() => {
    const multidoc = nft.doc.split(",");
    let docResult: any[] = [];
    multidoc.forEach((doc: any) => {
      const urlsplit = doc.split("/");
      docResult.push(
        urlsplit[urlsplit.length - 1] ||
          "7a7634bf-cd5c-4ba2-97c4-9daca4116ef9.pdf"
      );
    });
    setfile(docResult);
  });

  return (
    <>
      <CardWallet
        page={3}
        collection={collec}
        fromPoap={true}
        isPoa={false}
        image={nft.image}
      />
      <div className="nft-btn-col">
        <div className="nft-btn-row">
          <UtilityButton
            handleClick={() => {
              setPage(4);
            }}
            type={t("Document")}
          />
          <UtilityButton
            handleClick={() => {
              setPage(5);
            }}
            type={t("Chat")}
          />
          <UtilityButton
            handleClick={() => {
              setPage(8);
            }}
            type={t("Privilege")}
          />
        </div>
      </div>
      <div className="pass-QRcode">
      <QRCode
        size={256}
        style={{ width: "50%" }}
        value={"hello"}
        viewBox={`0 0 256 256`}
      />
    </div>
    </>
  );
};

interface UtilityProps {
  handleClick: any;
  type: any;
}

const UtilityButton = ({ handleClick, type }: UtilityProps) => {
  const { t } = useTranslation("mobileWallet");
  const icone =
    type == t("Chat") ? chat : type == t("Document") ? certif : priv;
  return (
    <div className="nft-btn">
      <div onClick={handleClick} className="nft-btn-content">
        <img src={icone} style={{ width: "10vw" }} />
      </div>
      {type}
    </div>
  );
};

export default DetailPoap;
