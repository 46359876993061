import React from 'react'
import { useNavigate } from 'react-router-dom';

import './Card.css'
import { useTranslation } from 'react-i18next';

export const CreateCard = () => {
    const navigate = useNavigate();
    const {t} = useTranslation("collectionCard");

    return <div className='card-create-background' onClick={() => {
        navigate('/poap')
    }}>
        <div className='card-plus'>
            +
        </div>
        <span style={{marginTop: '5vh', color: 'white', fontWeight: 'bold'}}>
            {t('create')}
        </span>
    </div>
}

export default CreateCard