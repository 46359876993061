import { useState, useEffect, useContext } from "react";
import "./Poap.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { backend, GlobalStateContext } from "../../App";
import QRCode from "react-qr-code";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import trash from "../../assets/poubelle.png";
import useFullPageLoader from "src/hooks/UseLoader";
import useEffectOnce from "src/hooks/UseEffectOnce";
import TextField from "@mui/material/TextField";

interface DetailsPoaProps {
  poas: any[];
  popSnack: any;
  jwt: string;
  promos: any[];
}

export const DetailsPoa = ({
  poas,
  popSnack,
  jwt,
  promos,
}: DetailsPoaProps) => {
  const [poa, setPoa] = useState<any>(null);
  const { t } = useTranslation("poap");
  const addr = window.location.href.split("/")[5];
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const get_poa = (poas: any[], addr: String, promos: any): any => {
    let tmp: any = null;
    poas.map((elm, idx) => {
      if (elm.address === addr) {
        tmp = elm;
      }
    });
    let offers = getOffers(promos, tmp.address);
    tmp.offers = offers;
    return tmp;
  };

  const getOffers = (promos: any[], addrPoap: String): any => {
    let offers: any[] = [];
    promos.map((elm, idx1) => {
      elm.addresses.map((addr: any, idx2: any) => {
        if (addr === addrPoap) {
          offers.push(elm);
        }
      });
    });
    return offers;
  };

  console.log(poa);
  useEffectOnce(() => {
    setPoa(get_poa(poas, addr, promos));
  });

  return (
    <>
      <div className="background-content">
        <Link
          to={"/collections"}
          style={{
            color: "#EF19D1",
            textDecoration: "none",
            alignSelf: "start",
            marginLeft: "5vw",
            fontSize: "1.5em",
          }}
          onClick={() => {}}
        >
          {"< " + t("goBack")}
        </Link>
        {poa !== null ? (
          <PoapInfo
            jwt={jwt}
            popSnack={popSnack}
            poa={poa}
            showLoader={showLoader}
            hideLoader={hideLoader}
          />
        ) : (
          <div style={{ color: "white" }}>{t("loading")}</div>
        )}
      </div>
      {loader}
    </>
  );
};

interface PaopInfoProps {
  poa: any;
  popSnack: any;
  jwt: string;
  showLoader: any;
  hideLoader: any;
}

const PoapInfo = ({
  poa,
  popSnack,
  jwt,
  showLoader,
  hideLoader,
}: PaopInfoProps) => {
  const [imgDisplay, setImg] = useState("");
  const [nbmint, setNbMint] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation("poap");
  const frontend = process.env.REACT_APP_FRONT || "http://172.20.10.4:3000";
  const urlRedirect = frontend + "/wallet?collec";
  const context = useContext(GlobalStateContext);
  const [refresh, setRefresh] = useState(true);
  const [price, setPrice] = useState(false);

  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar, setGlobalVar } = context;
  useEffect(() => {
    axios
      .get(
        `${poa.imgs[0]}?pinataGatewayToken=aK0mtd4Mv7XB6iPQon7tGOIdTKsBjVfLdDlWoihAIycxyoyi7M0udYF_7v9Fi191`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          responseType: "arraybuffer",
        }
      )
      .then((res) => {
        const binaryString = Array.from(new Uint8Array(res.data), (v) =>
          String.fromCharCode(v)
        ).join("");
        const theImage = btoa(binaryString);
        setImg(theImage);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .post(
        `${backend}/poap/minted`,
        { address: poa.address },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
            vault: globalVar,
          },
        }
      )
      .then((resp) => {
        setNbMint(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
        .post(
            `${backend}/poap/payment`,
            {
              collec: poa?.address,
              type: "poap",
            },
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API,
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((resp) => {
            if (resp.data !== 0) {
              setPrice(true);
            }
            console.log("price data", resp.data)
          })
          .catch((err) => {
            console.log(err);
            popSnack(t("errorPayment"), "error");
          });
  });

  const removePromotion = (code: string) => {
    if (typeof showLoader === "function") {
      showLoader();
    }
    axios
      .post(
        `${backend}/promo/removepromo`,
        { code: code, addr: poa.address },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API,
            authorization: jwt,
            vault: globalVar,
          },
        }
      )
      .then((resp) => {
        console.log(resp);
        popSnack(
          "The promo has been successfully removed from the collection",
          "success"
        );
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        if (poa.offers != undefined) {
          for (let i = 0; i < poa.offers.length; i++) {
            if (poa.offers[i].code === code) {
              poa.offers[i] = poa.offers[poa.offers.length - 1];
              poa.offers.pop();
              break;
            }
          }
        }
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log(err);
        if (typeof hideLoader === "function") {
          hideLoader();
        }
        popSnack("An error occured while removing the promo", "error");
      });
  };

  const mailCode = () => {

    if (typeof showLoader === "function") {
      showLoader();
    } 
    axios
        .post(
            `${backend}/poap/generatecode`,
            { addr: poa.address, name: poa.eventName},
            {
              headers: {
                "x-api-key": process.env.REACT_APP_API,
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((resp) => {
            var templateParams = {
              from_name: "Customer Service",
              code: resp.data,
              link_to: `${urlRedirect}=${poa.address}&type=poap`,
              to_email: email,
            };
        
            emailjs
              .send(
                "NFT_Experience",
                "template_n3t3uhn",
                templateParams,
                "dI1NV4PWOS0ci1eLZ"
              )
              .then(
                function (response: any) {
                  if (typeof hideLoader === "function") {
                    hideLoader();
                  }
                  popSnack(t("emailSent"), "success");
                  console.log("SUCCESS!", response.status, response.text);
                },
                function (error: any) {
                  if (typeof hideLoader === "function") {
                    hideLoader();
                  }
                  popSnack(t("errorOccurred"), "error");
                  console.log("FAILED...", error);
                }
              );
          })
          .catch((err) => {
            console.log(err);
            popSnack(t("errorOccurred"), "error");
            if (typeof hideLoader === "function") {
              hideLoader();
            }
          });
  }

  const mail = () => {
    if (typeof showLoader === "function") {
      showLoader();
    } 
    var templateParams = {
      from_name: "Customer Service",
      link_to: `${urlRedirect}=${poa.address}&type=poap`,
      to_email: email,
    };

    emailjs
      .send(
        "NFT_Experience",
        "template_iywots5",
        templateParams,
        "dI1NV4PWOS0ci1eLZ"
      )
      .then(
        function (response: any) {
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          popSnack(t("emailSent"), "success");
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error: any) {
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          popSnack(t("errorOccurred"), "error");
          console.log("FAILED...", error);
        }
      );
  }

  return (
    <div className="mid-poap">
      <div className="mid-detail-poap">
        <div className="poap-info">
          <div className="poap-info-image">
            <img
              src={`data:image/png;base64,${imgDisplay}`}
              className="poap-img"
            />
          </div>
          <div className="poap-info-txt">
            <span style={{ color: "white" }}>{poa.eventName}</span>
            <span style={{ fontWeight: "initial", fontSize: "0.5em" }}>
              {poa.date}
            </span>
            <span
              style={{
                fontWeight: "initial",
                fontSize: "0.6em",
                marginTop: "3vh",
              }}
            >
              {poa.description}
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                flexDirection: "row",
              }}
            >
              <span
                style={{
                  marginRight: "1vw",
                  color: "#EF19D1",
                  fontSize: "2em",
                }}
              >{`${nbmint} `}</span>
              <span
                style={{
                  color: "white",
                  fontWeight: "initial",
                  marginBottom: "0.7vh",
                }}
              >
                {t("minted")}
              </span>
            </div>
          </div>
        </div>
        <div className="poap-QRcode">
          <QRCode
            size={256}
            style={{ width: "90%", margin: "10px" }}
            value={`${urlRedirect}=${poa.address}&type=poap`}
            viewBox={`0 0 256 256`}
          />
          <TextField autoComplete='off' variant="outlined" label="email" style={{ minWidth: "20vw", border: "green" }} className="txtfield-offer" value={email} onChange={(e: any) => {
                        setEmail(e.target.value)
                    }} />
          <button
            className="button-email"
            onClick={() => {
              price ? mailCode() : mail()
            }}
          >
            {t("sendEmail")}
          </button>
        </div>
      </div>
      <div className="mid-offers-poap">
        <div className="mid-offer-privilege">{t("ListPrivilege")}</div>
        <div style={{overflowY: "scroll", scrollbarWidth: 'none', height: "80%"}}>
        {poa.offers.length > 0 ? (
          poa.offers.map((elm: any, idx: any) => {
            return (
              <div className="mid-offer-poap">
                <div className="mid-offer-txt">
                  <div className="mid-offer-title">{elm.name}</div>
                  <div className="mid-offer-desc">{elm.description}</div>
                </div>
                <div
                  className="mid-offer-delete"
                  onClick={() => {
                    removePromotion(elm.code);
                  }}
                >
                  <img src={trash} style={{ width: "25px" }} />
                </div>
              </div>
            );
          })
        ) : (
          <div className="mid-offer-poap" style={{ background: "unset" }}>
            <div className="mid-offer-txt">
              <div className="mid-offer-desc">{t("noPrivilege")}</div>
            </div>
          </div>
        )}
        </div>
      </div>
    </div>
  );
};

export default DetailsPoa;
