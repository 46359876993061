import { useTranslation } from "react-i18next";
import "../../MobileApp.scss";
import CardWallet from "../CardWallet";

interface OfferProps {
  offers: any;
  collec: any;
}

export const OfferWallet = ({ offers, collec }: OfferProps) => {
  console.log("offres", offers);
  const { t } = useTranslation("mobileWallet");

  const openlink = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <>
      <div className="wallet-offers">
        {offers.map((elm: any, idx: number) => {
          return (
            <div
              className="wallet-offers-btn"
              onClick={() => openlink(elm.url)}
            >
              <div className="wallet-offers-txt">
                <div className="wallet-offers-txt-title">{elm.name}</div>
                <div className="wallet-offers-txt-desc">{elm.description}</div>
              </div>
              <div className="wallet-more-content">
                <span
                  className="wallet-offers-txt-more"
                  style={{ color: "white" }}
                >
                  {t("ViewMore")}
                </span>
              </div>
            </div>
          );
        })}
        {offers.length == 0 ? <p>{t("noPrivilege")}</p> : <></>}
      </div>
    </>
  );
};

export default OfferWallet;
