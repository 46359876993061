import axios from "axios";
import "../../MobileApp.scss";
import React, { useEffect, useState } from "react";
import { backend } from "src/App";
import { Document, Page, pdfjs } from "react-pdf";
import { useTranslation } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface CertificateNFTProps {
  file: any;
  setpage: any;
  page: any;
}

export const CertificateNFT = ({
  file,
  setpage,
  page,
}: CertificateNFTProps) => {
  const [imgDisplay, setImg] = useState("");
  const [dlImg, setdlImg] = useState(new ArrayBuffer(1000));
  const [pdfDisplay, setPdf] = useState<string | null>(null); // Initialisation avec null
  const { t } = useTranslation("mobileWallet");

  const downloadImage = () => {
    var bytes = new Uint8Array(dlImg);

    var blob = new Blob([bytes], { type: "application/pdf" });

    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = file;
    link.click();
  };

  const fetchImage = (imageUrl: string) => {
    axios
      .get(`${backend}/files/download/certif/${imageUrl}`, {
        headers: { "x-api-key": process.env.REACT_APP_API },
        responseType: "arraybuffer",
      })
      .then((res) => {
        setdlImg(res.data);
        const binaryString = Array.from(new Uint8Array(res.data), (v) =>
          String.fromCharCode(v)
        ).join("");
        const theImage = btoa(binaryString);
        setImg(theImage);
        const blob = new Blob([res.data], { type: "application/pdf" });
        const pdfDataUri = URL.createObjectURL(blob);
        setPdf(pdfDataUri);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openDoc = (url: string) => {
    fetchImage(url);
    setpage(9);
  };

  useEffect(() => {
    if (!imgDisplay) {
      fetchImage(file);
    }
  }, [imgDisplay, file]);

  return (
    <>
      <div className="wallet-offers">
        {page != 9 ? (
          file.map((doc: any, index: any) => (
            <div className="wallet-offers-btn" onClick={() => openDoc(doc)}>
              <div className="wallet-offers-txt">
                <div
                  className="wallet-offers-txt-title"
                  style={{ marginTop: "24%" }}
                >
                  Document {index + 1}
                </div>
              </div>
              <div className="wallet-more-content">
                <span
                  className="wallet-offers-txt-more"
                  style={{ color: "white" }}
                >
                  {t("ViewMore")}
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className="certificate">
            {imgDisplay && (
              <div className="certificate-bg-img">
                <Document
                  file={pdfDisplay}
                  className="certificate-img"
                  onLoadSuccess={() => {}}
                >
                  <Page pageNumber={1} />
                </Document>
              </div>
            )}

            <div className="nft-btn">
              <div
                className="nft-btn-content"
                style={{ width: "23vw", height: "7vh", marginBottom: "5vh" }}
                onClick={downloadImage}
              >
                {t("Download")}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CertificateNFT;
