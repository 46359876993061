import QRCode from "react-qr-code";
import "../MobileApp.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const MobileQrcode = () => {
  const [navigatorType, setNavigatorType] = useState("none");

  const { t } = useTranslation("mobileWallet");

  const emulator = { height: "100vh" };
  const phone = { height: "-webkit-fill-available" };

  useEffect(() => {
    setNavigatorType(window?.navigator?.platform);
  }, [])

  return (
    <>
      <div className="wallet-background" style={navigatorType === "MacIntel" ? emulator : phone}>
        <div className="wallet-title-city" style={{margin: "50px"}}>
          {t("qrcodeOffer")}
        </div>
      
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80%",
            marginBottom: "40vh",
          }}
        >
          <QRCode value={"qrcode"} size={200} />
        </div>
      </div>
    </>
  );
};

export default MobileQrcode;
