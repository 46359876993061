import { TextField } from "@mui/material";
import React, { useState } from "react";
import useFullPageLoader from "src/hooks/UseLoader";
import axios from "axios";
import { backend } from "../../../App";
import { useTranslation } from "react-i18next";

interface CreateAbstractAccProps {
  popSnack: any;
  setAccConnected: any;
  setAcc: any;
  setPage: any;
}

export const CreateAbstractAcc = ({
  popSnack,
  setAccConnected,
  setAcc,
  setPage,
}: CreateAbstractAccProps) => {
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confpassword, setConfPassword] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { t } = useTranslation("mobileConnection");

  const urlProd = process.env.REACT_APP_PROD_URL || "http://52.47.98.192";
  const portAbstract = process.env.REACT_APP_PORT_ABSTRACT || "5005";
  const urlwallet = urlProd + "/address";

  const createAccount = async () => {
    // access new acc
    if (
      fName == "" ||
      lName == "" ||
      email == "" ||
      password == "" ||
      confpassword == ""
    ) {
      popSnack(t("fillInfos"), "warning");
    } else if (password != confpassword) {
      popSnack(t("notSamePwd"), "warning");
      setPassword("");
      setConfPassword("");
    } else {
      if (typeof showLoader === "function") {
        showLoader();
      }
      let acc = "";
      await axios
        .post(
          urlwallet,
          { email: email, password: password },
          { headers: { "x-api-key": process.env.REACT_APP_API } }
        )
        .then(async (resp) => {
          acc = resp.data.address;
          setAcc(resp.data.address);
          localStorage.setItem("token", resp.data.token);
          console.log("created address : ", resp.data.address, acc);
          await axios
            .post(
              `${backend}/client/add`,
              { email: email, name: fName + " " + lName, wallet: acc },
              {
                headers: {
                  "x-api-key": process.env.REACT_APP_API,
                  Authorization: localStorage.getItem("token"),
                },
              }
            )
            .then((resp) => {
              localStorage.setItem("privatekeyXmtp", resp.data.privatekey);
              if (resp.data.wallet === "none") {
                popSnack(t("alreadyExisting"), "success");
              } else {
                popSnack(t("accountCreated"), "success");
              }
              setAccConnected(true);
              if (typeof hideLoader === "function") {
                hideLoader();
              }
            })
            .catch(() => {
              if (typeof hideLoader === "function") {
                hideLoader();
              }
              popSnack(t("errorCreating"), "error");
            });
        })
        .catch((err) => {
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className="wallet-user">
        <div className="wallet-back">
          <button
            className="wallet-back-btn"
            onClick={() => {
              setPage(1);
            }}
          >
            <div className="wallet-back-txt">&#60;&#32;{t("back")}</div>
          </button>
        </div>
        <div className="wallet-user-txt">{t("createAccount")}</div>
        <div className="wallet-user-form">
          <div>
            <TextField
              className="send-txtfield"
              autoComplete="off"
              variant="outlined"
              label={t("firstName")}
              style={{
                minWidth: "20vw",
                maxWidth: "40vw",
                marginTop: "3vh",
                marginRight: "2vw",
              }}
              value={fName}
              onChange={(e) => setfName(e.target.value)}
            />
            <TextField
              className="send-txtfield"
              autoComplete="off"
              variant="outlined"
              label={t("lastName")}
              style={{
                minWidth: "20vw",
                maxWidth: "40vw",
                marginTop: "3vh",
                marginLeft: "2vw",
              }}
              value={lName}
              onChange={(e) => setlName(e.target.value)}
            />
          </div>
          <TextField
            className="send-txtfield"
            autoComplete="off"
            variant="outlined"
            label={t("emailAddress")}
            style={{ minWidth: "22vw", marginTop: "3vh", marginInline: "3vw" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            className="send-txtfield"
            autoComplete="off"
            variant="outlined"
            type="password"
            label={t("password")}
            style={{ minWidth: "22vw", marginTop: "3vh", marginInline: "3vw" }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            className="send-txtfield"
            autoComplete="off"
            variant="outlined"
            type="password"
            label={t("confirm")}
            style={{ minWidth: "22vw", marginTop: "3vh", marginInline: "3vw" }}
            value={confpassword}
            onChange={(e) => setConfPassword(e.target.value)}
          />
          <div style={{ alignItems: "center", justifyContent: "center" }}>
            <button
              className="wallet-user-btn-crea-aa"
              onClick={() => {
                createAccount();
              }}
            >
              {t("createAccount")}
            </button>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
};

export default CreateAbstractAcc;
