import axios from "axios";
import { useContext, useState } from "react";
import { backend, GlobalStateContext } from "src/App";
import useFullPageLoader from "src/hooks/UseLoader";
import emailjs from "@emailjs/browser";
import { TextField } from "@mui/material";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";

interface CustomerProps {
  nft: number;
  SetSendPage: any;
  popSnack: any;
  addr: string;
  from: string;
  jwt: string;
}

export const SendCustomer = ({
  nft,
  SetSendPage,
  popSnack,
  addr,
  from,
  jwt,
}: CustomerProps) => {
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [acc, setAcc] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const frontend = process.env.REACT_APP_FRONT || "http://172.20.10.4:3000";
  const urlRedirect = frontend + "/wallet?collec";
  const { t } = useTranslation("passportProduct");
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(
      "MonComposant doit être utilisé à l'intérieur d'un GlobalStateProvider"
    );
  }
  const { globalVar } = context;

  const sendNft = () => {
    if (fName == "" || lName == "" || email == "" || acc == "") {
      popSnack("Please fill all informations", "warning");
    } else {
      if (typeof showLoader === "function") {
        showLoader();
      }
      axios
        .post(
          `${backend}/client/send`,
          {
            wallet: acc,
            name: fName + " " + lName,
            email: email,
            from: from,
            contractAddr: addr,
            tokenId: nft,
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_API,
              Authorization: jwt,
              vault: globalVar,
            },
          }
        )
        .then((resp) => {
          console.log(resp);
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          popSnack("NFT sent successfully", "success");
        })
        .catch((err) => {
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          popSnack("Error occured while sending the nft", "error");
          console.log(err);
        });
    }
  };

  const sendEmail = () => {
    if (email == "") {
      popSnack("Please fill the email field first", "warning");
      return;
    }
    var templateParams = {
      to_name: fName + " " + lName,
      from_name: "Hermès Customer Service",
      link_to: `http://13.36.226.45:3001/wallet?collec=${addr}&idNFT=${nft}`,
      to_email: email,
    };

    if (typeof showLoader === "function") {
      showLoader();
    }
    emailjs
      .send(
        "NFT_Experience",
        "template_iywots5",
        templateParams,
        "dI1NV4PWOS0ci1eLZ"
      )
      .then(
        function (response: any) {
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          popSnack("Email sent successfully", "success");
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error: any) {
          if (typeof hideLoader === "function") {
            hideLoader();
          }
          popSnack("An error occured while sending the nft by email", "error");
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <>
      <div className="send-background">
        <button
          style={{
            color: "#EF19D1",
            background: "none",
            border: "none",
            cursor: "pointer",
            textDecoration: "none",
            alignSelf: "start",
            marginLeft: "5vw",
            marginTop: "15vh",
          }}
          onClick={() => {
            SetSendPage(false);
          }}
        >
          {t("goBack")}
        </button>
        <h2
          style={{
            color: "#078BF9",
            minWidth: "70vw",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {t("Fill")}
        </h2>
        <div className="send-user-info-middle">
          <div className="send-form-background">
            <TextField
              className="send-txtfield"
              autoComplete="off"
              variant="outlined"
              label={t("FName")}
              style={{ minWidth: "22vw", marginTop: "3vh" }}
              value={fName}
              onChange={(e) => setfName(e.target.value)}
            />
            <TextField
              className="send-txtfield"
              autoComplete="off"
              variant="outlined"
              label={t("LName")}
              style={{ minWidth: "22vw", marginTop: "3vh" }}
              value={lName}
              onChange={(e) => setlName(e.target.value)}
            />
            <TextField
              className="send-txtfield"
              autoComplete="off"
              variant="outlined"
              label={t("Email")}
              style={{ minWidth: "22vw", marginTop: "3vh" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              className="send-txtfield"
              autoComplete="off"
              variant="outlined"
              label={t("Wallet")}
              style={{ minWidth: "22vw", marginTop: "3vh" }}
              value={acc}
              onChange={(e) => setAcc(e.target.value)}
            />
            <button
              style={{
                cursor: "pointer",
                minWidth: "22vw",
                minHeight: "5vh",
                backgroundColor: "#EF19D1",
                border: "none",
                marginTop: "4vh",
                color: "white",
                fontWeight: "bold",
                borderRadius: "20px",
              }}
              onClick={() => {
                sendNft();
              }}
            >
              {t("Send")}
            </button>
          </div>
          <div className="send-form-background">
            <div
              style={{
                minWidth: "25vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <QRCode
                size={256}
                style={{
                  height: "auto",
                  maxWidth: "60%",
                  width: "60%",
                  marginTop: "5%",
                }}
                value={`${urlRedirect}=${addr}&idNFT=${nft}`}
                viewBox={`0 0 256 256`}
              />
            </div>
            <TextField
              className="send-txtfield"
              autoComplete="off"
              variant="outlined"
              label={t("Email")}
              style={{ minWidth: "22vw", marginTop: "3vh" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              style={{
                cursor: "pointer",
                minWidth: "22vw",
                minHeight: "5vh",
                backgroundColor: "#EF19D1",
                border: "none",
                marginTop: "4vh",
                color: "white",
                fontWeight: "bold",
                borderRadius: "20px",
              }}
              onClick={() => {
                sendEmail();
              }}
            >
              {t("SendEmail")}
            </button>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
};

export default SendCustomer;
