import React, { useContext, useState } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Connect from "../ConnectWallet/Connect";
import logo from "../../assets/CityPass.png";
import { Languages } from "../Language/Language";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyBoardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { GlobalStateContext } from "../../App";
import changeimg from "../../assets/passport/transfert_black.png";

interface HeaderProps {
  wallets: { address: string; name: string; id: number; vault: string }[];
  setCurrWallet: any;
  currWallet: { address: string; name: string; id: number; vault: string };
  checkRole: any;
}

export const HeaderSide = ({
  wallets,
  setCurrWallet,
  currWallet,
  checkRole,
}: HeaderProps) => {
  const [update, setUpdate] = useState(false);
  const [subnav, setSubnav] = useState(false);
  const [subnavAdm, setSubnavAdm] = useState(false);
  const [subnavChat, setSubnavChat] = useState(false);

  function getName() {
    const tokenString = localStorage.getItem("name");
    const userToken = tokenString ? tokenString : "";
    return userToken;
  }

  const name = getName();

  const location = useLocation().pathname;
  const selected = location.split("/")[1];
  const selectedAdm = location.split("/")[2];
  const pink = { color: "#EF19D1" };
  const white = { color: "white" };
  const { t } = useTranslation("header");

  return (
    <div className="header-side-background">
      <Link
        to="/home"
        onClick={() => setUpdate(!update)}
        style={{ textDecoration: "none" }}
      >
        <img src={logo} style={{ maxWidth: "13vw", marginTop: "5vh" }} />
      </Link>

      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "row",
          marginTop: "5vh",
          minWidth: "15vw",
          marginBottom: "3vh",
        }}
      >
        <AccountCircleIcon style={{ color: "white" }} fontSize="large" />
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
            marginLeft: "1vw",
          }}
        >
          <span style={{ color: "white", fontWeight: "bold" }}>
            {t("profile")}
          </span>
          <span style={{ color: "#e2e2e2", fontSize: "0.8em" }}>{name}</span>
        </div>
      </div>
      <SelectWallet currWallet={currWallet} />
      {currWallet.name !== "" ? (
        <>
          <div className="separator" />
        </>
      ) : (
        <></>
      )}

      <div className="section-title">
        {checkRole("adm01") ? (
          <>
            <div className="subnav-content">
              <button
                className="link-header button-subnav"
                style={
                  (selected === "permissions" ||
                    selected === "role" ||
                    selected === "wallet") &&
                    !subnav
                    ? pink
                    : white
                }
                onClick={() => setSubnavAdm(!subnavAdm)}
              >
                Administration
              </button>
              <div className="arrow">
                <KeyBoardDoubleArrowLeftIcon
                  className={subnavAdm ? "arrow-mv" : "arrow-mv-inv"}
                />
              </div>
            </div>
            <SubNavAdm
              selected={selectedAdm}
              subnav={subnavAdm}
              checkRole={checkRole}
            />
          </>
        ) : (
          <></>
        )}
        {checkRole("col00") ? (
          <Link
            to="/collections"
            onClick={() => setUpdate(!update)}
            className={
              selected === "collections"
                ? "link-header-selected"
                : "link-header"
            }
          >
            {t("collections")}
          </Link>
        ) : (
          <></>
        )}
        {checkRole("poa00") ? (
          <Link
            to="/poap"
            className={
              selected === "poap"
                ? "link-header-selected"
                : "link-header"
            }
            onClick={() => setUpdate(!update)}
          >
            {t("createCollections")}
          </Link>
        ) : (
          <></>
        )}
        {/* {checkRole("col01") || checkRole("poa00") ? (
          <>
            <div className="subnav-content">
              <button
                className="link-header button-subnav"
                style={
                  (selected === "newcollection" || selected === "poap") &&
                    !subnav
                    ? pink
                    : white
                }
                onClick={() => setSubnav(!subnav)}
              >
                {t("createCollections")}
              </button>
              <div className="arrow">
                <KeyBoardDoubleArrowLeftIcon
                  className={subnav ? "arrow-mv" : "arrow-mv-inv"}
                />
              </div>
            </div>
            <SubNav selected={selected} subnav={subnav} checkRole={checkRole} />
          </>
        ) : (
          <></>
        )} */}
        {/* {checkRole("sho01") ? (
          <Link
            to="showroom"
            onClick={() => setUpdate(!update)}
            className={
              selected === "showroom" ? "link-header-selected" : "link-header"
            }
          >
            {t("showroom")}
          </Link>
        ) : (
          <></>
        )} */}
        {checkRole("col01") ? (
          <Link
            to="nft"
            onClick={() => setUpdate(!update)}
            className={
              selected === "nft" ? "link-header-selected" : "link-header"
            }
          >
            {t("nft")}
          </Link>
        ) : (
          <></>
        )}
        {/* {checkRole("cus00") ? (
          <Link
            to="transferRequest"
            onClick={() => setUpdate(!update)}
            className={
              selected === "transferRequest"
                ? "link-header-selected"
                : "link-header"
            }
          >
            {t("TransferRequest")}
          </Link>
        ) : (
          <></>
        )} */}
        {checkRole("col01") || checkRole("poa00") ? (
          <>
            <div className="subnav-content">
              <button
                className="link-header button-subnav"
                style={
                  (selected === "broadcast" || selected === "chat" || selected === "community") &&
                    !subnavChat
                    ? pink
                    : white
                }
                onClick={() => setSubnavChat(!subnavChat)}
              >
                {t("MsgCommunity")}
              </button>
              <div className="arrow">
                <KeyBoardDoubleArrowLeftIcon
                  className={subnavChat ? "arrow-mv" : "arrow-mv-inv"}
                />
              </div>
            </div>
            <SubNavChat
              selected={selected}
              subnav={subnavChat}
              checkRole={checkRole}
            />
          </>
        ) : (
          <></>
        )}
        {checkRole("cus00") ? (
          <Link
            to="customers"
            onClick={() => setUpdate(!update)}
            className={
              selected === "customers" ? "link-header-selected" : "link-header"
            }
          >
            {t("customer")}
          </Link>
        ) : (
          <></>
        )}
        {/* {checkRole("col01") ? (
          <Link
            to="/mass"
            onClick={() => setUpdate(!update)}
            className={
              selected === "mass" ? "link-header-selected" : "link-header"
            }
          >
            {t("MassCreation")}
          </Link>
        ) : (
          <></>
        )} */}
      </div>
    </div>
  );
};

interface SubNavProps {
  selected: string;
  subnav: boolean;
  checkRole: any;
}

const SubNav = ({ selected, subnav, checkRole }: SubNavProps) => {
  const pink = { color: "#EF19D1" };
  const white = { color: "white" };
  const { t } = useTranslation("header");

  return (
    <div className={subnav ? "link-subtitle-vis" : "link-subtitle"}>
      {checkRole("col01") ? (
        <Link
          className="subtitle"
          style={selected === "newcollection" ? pink : white}
          to="/newcollection"
        >
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("virtual")}
          </span>
        </Link>
      ) : (
        <></>
      )}
      {checkRole("poa00") ? (
        <Link
          className="subtitle"
          style={selected === "poap" ? pink : white}
          to="/poap"
        >
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("POA")}
          </span>
        </Link>
      ) : (
        <></>
      )}
      {checkRole("poa00") ? (
        <Link
          className="subtitle"
          style={selected === "pass" ? pink : white}
          to="/pass"
        >
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("Pass")}
          </span>
        </Link>
      ) : (
        <></>
      )}
      {checkRole("col01") ? (
        <Link
          className="subtitle"
          style={selected === "collectibles" ? pink : white}
          to="/collectibles"
        >
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {t("collectibles")}
          </span>
        </Link>
      ) : (
        <></>
      )}
    </div>
  );
};

const SubNavAdm = ({ selected, subnav, checkRole }: SubNavProps) => {
  const pink = { color: "#EF19D1" };
  const white = { color: "white" };
  const { t } = useTranslation("header");

  return (
    <div className={subnav ? "link-subtitle-vis" : "link-subtitle"}>
      <Link
        className="subtitle"
        style={selected === "permission" ? pink : white}
        to="administration/permission"
      >
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {t("Permission")}
        </span>
      </Link>
      <Link
        className="subtitle"
        style={selected === "role" ? pink : white}
        to="/administration/role"
      >
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {t("Role")}
        </span>
      </Link>
      <Link
        className="subtitle"
        style={selected === "wallet" ? pink : white}
        to="/administration/wallet"
      >
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("Wallet")}
        </span>
      </Link>
      <Link
        className="subtitle"
        style={selected === "network" ? pink : white}
        to="/administration/network"
      >
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("Network")}
        </span>
      </Link>
    </div>
  );
};

interface SelectWalletProps {
  currWallet: { address: string; name: string; id: number; vault: string };
}

const SelectWallet = ({ currWallet }: SelectWalletProps) => {
  return (
    <div className="wallet-info">
      {currWallet.name}
      <div className="change-wallet-content-img">
        <Link to="/walletSelect">
          <img src={changeimg} alt="changeWalletImg" className="change-img" />
        </Link>
      </div>
    </div>
  );
};

interface SubNavChatProps {
  selected: string;
  subnav: boolean;
  checkRole: any;
}

const SubNavChat = ({ selected, subnav, checkRole }: SubNavChatProps) => {
  const pink = { color: "#EF19D1" };
  const white = { color: "white" };
  const { t } = useTranslation("header");

  return (
    <div className={subnav ? "link-subtitle-vis" : "link-subtitle"}>
      {/* {checkRole("col01") ? ( */}
      <Link
        className="subtitle"
        style={selected === "broadcast" ? pink : white}
        to="/broadcast"
      >
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {t("Broadcast")}
        </span>
      </Link>
      {/*  ) : (
         <></>
       )} */}
      {/* {checkRole("poa00") ? ( */}
      <Link
        className="subtitle"
        style={selected === "chat" ? pink : white}
        to="/chat"
      >
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chat</span>
      </Link>
      <Link
        className="subtitle"
        style={selected === "community" ? pink : white}
        to="/community"
      >
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("community")}</span>
      </Link>
      {/* ) : (
        <></>
      )} */}
    </div>
  );
};

export default HeaderSide;
