import {useState } from "react";
import "../Header/Header.css";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/CityPass.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyBoardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import changeimg from "../../assets/passport/transfert_black.png";

interface HeaderProps {
  currWallet: { address: string; name: string; id: number; vault: string };
  nbCollec: number;
  nbPromos: number;
  distributed: number;
  nbOwners: number;
  setHomeBar: any;
}

export const HeaderSide = ({
  currWallet,
  nbCollec,
  nbPromos,
  distributed,
  nbOwners,
  setHomeBar
}: HeaderProps) => {
  const [subnav, setSubnav] = useState(false);
  const [subnavAdm, setSubnavAdm] = useState(false);

  function getName() {
    const tokenString = localStorage.getItem("name");
    const userToken = tokenString ? tokenString : "";
    return userToken;
  }

  const name = getName();

  const location = useLocation().pathname;
  const selected = location.split("/")[1];
  const selectedAdm = location.split("/")[2];
  const pink = { color: "#EF19D1" };
  const white = { color: "white" };
  const { t } = useTranslation("dashboard");

  return (
    <div className="header-side-background">
      <img src={logo} style={{ maxWidth: "13vw", marginTop: "5vh" }} />

      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "row",
          marginTop: "5vh",
          minWidth: "15vw",
          marginBottom: "3vh",
        }}
      >
        <AccountCircleIcon style={{ color: "white" }} fontSize="large" />
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
            marginLeft: "1vw",
          }}
        >
          <span style={{ color: "white", fontWeight: "bold" }}>
            {t("profile")}
          </span>
          <span style={{ color: "#e2e2e2", fontSize: "0.8em" }}>{name}</span>
        </div>
      </div>
      <SelectWallet currWallet={currWallet} setHomeBar={setHomeBar}/>
      <div className="separator" />

      <div className="section-title">
        <div className="subnav-content">
          <button
            className="link-header button-subnav"
            style={
              (selected === "permissions" ||
                selected === "role" ||
                selected === "wallet") &&
                !subnav
                ? pink
                : white
            }
            onClick={() => {setSubnavAdm(!subnavAdm)}}
          >
            Administration
          </button>
          <div className="arrow">
            <KeyBoardDoubleArrowLeftIcon
              className={subnavAdm ? "arrow-mv" : "arrow-mv-inv"}
            />
          </div>
        </div>
        <SubNavAdm
          selected={selectedAdm}
          subnav={subnavAdm}
          setHomeBar={setHomeBar}
        />
        <Link
            to="/customers"
            onClick={() => setHomeBar(false)}
            className={
              selected === "customers" ? "link-header-selected" : "link-header"
            }
          >
            {t("customer")}
          </Link>
        
        <div className='stats'>
        <div style={{fontSize: "x-large", color: "white", marginBottom: "20px", fontWeight: 'bold'}}>
          Vos chiffres clefs
        </div>
        <Stat num={nbCollec} txt={t('deployed')} />
        <div style={{ minWidth: '12vw', borderTop: "0.8px solid #e2e2e2", margin: "20px" }}></div>
        <Stat num={distributed} txt={t('distributed')} />
        <div style={{ minWidth: '12vw', borderTop: "0.8px solid #e2e2e2", margin: "20px" }}></div>
        <Stat num={nbPromos} txt={t('offers')} />
        <div style={{ minWidth: '12vw', borderTop: "0.8px solid #e2e2e2", margin: "20px" }}></div>
        <Stat num={nbOwners} txt={t('owners')} />
        <div style={{ marginRight: "3vw" }} />
        </div>
      </div>
    </div>
  );
};

interface SubNavProps {
  selected: string;
  subnav: boolean;
  setHomeBar: any;
}

const SubNavAdm = ({ selected, subnav, setHomeBar }: SubNavProps) => {
  const pink = { color: "#EF19D1" };
  const white = { color: "white" };
  const { t } = useTranslation("header");

  return (
    <div className={subnav ? "link-subtitle-vis" : "link-subtitle"}>
      <Link
        className="subtitle"
        style={selected === "permission" ? pink : white}
        to="administration/permission"
        onClick={() => setHomeBar(false)}
      >
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {t("Permission")}
        </span>
      </Link>
      <Link
        className="subtitle"
        style={selected === "role" ? pink : white}
        to="/administration/role"
        onClick={() => setHomeBar(false)}
      >
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {t("Role")}
        </span>
      </Link>
      <Link
        className="subtitle"
        style={selected === "wallet" ? pink : white}
        to="/administration/wallet"
        onClick={() => setHomeBar(false)}
      >
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("Wallet")}
        </span>
      </Link>
      <Link
        className="subtitle"
        style={selected === "network" ? pink : white}
        to="/administration/network"
        onClick={() => setHomeBar(false)}
      >
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("Network")}
        </span>
      </Link>
    </div>
  );
};

interface SelectWalletProps {
  currWallet: { address: string; name: string; id: number; vault: string };
  setHomeBar: any;
}

const SelectWallet = ({ currWallet, setHomeBar }: SelectWalletProps) => {
  return (
    <div className="wallet-info">
      {currWallet.name}
      <div className="change-wallet-content-img">
        <Link to="/walletSelect" onClick={() => setHomeBar(false)}>
          <img src={changeimg} alt="changeWalletImg" className="change-img" />
        </Link>
      </div>
    </div>
  );
};

interface StatsProps {
  num: number
  txt: string
}

export const Stat = ({ num, txt }: StatsProps) => {
  return <div style={{ width: "100%", display: "flex", justifyContent: "left", alignItems: 'center', flexDirection: 'row', marginLeft: "5vw" }}>
      <span style={{ fontSize: 'xx-large', color: '#EF19D1' }}>
          {num}
      </span>
      <span style={{ fontSize: 'large', color: 'white', textAlign: 'center', marginLeft: "10px" }}>
          {txt}
      </span>
  </div>
}


export default HeaderSide;
