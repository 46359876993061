import { useTranslation } from "react-i18next";
import './Dashboard.css'
import HeaderSide from "./HeaderSide";
import city from "../../assets/citypassblackv3.png";
import { Link } from "react-router-dom";

import connexion from "../../assets/passport/connexion.png";
import chat from "../../assets/passport/chat.png";
import priv from "../../assets/passport/privilege.png";
import mint from "../../assets/passport/mint.png";
import transfert from "../../assets/passport/transfert.png";
import send from "../../assets/passport/send.png";

interface DashProps {
    nfts: any
    poap: any
    promos: any
    supplyVP: any
    supplyPoaps: any
    nbOwners: any
    currentWallet: any
    setHomeBar: any
}

export const Dashboard = ({nfts, poap, promos, supplyPoaps, supplyVP, nbOwners, currentWallet, setHomeBar}: DashProps) => {
    const nbCollec = nfts.length + poap.length
    const nbPromos = promos.length
    const distributed = supplyVP + supplyPoaps
    const {t} = useTranslation("header");
    setHomeBar(true)
    
    return  (
    <>
        <HeaderSide currWallet={currentWallet} nbCollec={nbCollec} nbPromos={nbPromos} distributed={distributed} nbOwners={nbOwners} setHomeBar={setHomeBar}/>
        <div className="home-content">
            <img src={city} style={{ minWidth: "100%", height: "30vh" }} />
            <div className="home-btn-col">
                <div className="home-btn-row">
                    <div className="home-btn">
                            <Link
                                to="/collections"
                                className="home-btn-content"
                                onClick={() => setHomeBar(false)}
                            >
                                <img src={connexion} style={{ width: "30%", marginBottom: "2vh" }} />
                                {t("collections")}
                            </Link>
                    </div>
                    <div className="home-btn">
                            <Link
                                to="/poap"
                                className="home-btn-content"
                                onClick={() => setHomeBar(false)}
                            >
                                <img src={mint} style={{ width: "30%", marginBottom: "2vh" }} />
                                {t("createCollections")}
                            </Link>
                    </div>
                    <div className="home-btn">
                            <Link
                                to="/nft"
                                className="home-btn-content"
                                onClick={() => setHomeBar(false)}
                            >
                                <img src={send} style={{ width: "30%", marginBottom: "2vh" }} />
                                {t("nft")}
                            </Link>
                    </div>
                </div>
                <div className="home-btn-row">
                    <div className="home-btn">
                            <Link
                                to="/chat"
                                className="home-btn-content"
                                onClick={() => setHomeBar(false)}
                            >
                                <img src={chat} style={{ width: "30%", marginBottom: "2vh" }} />
                                Chat
                            </Link>
                    </div>
                    <div className="home-btn">
                            <Link
                                to="/community"
                                className="home-btn-content"
                                onClick={() => setHomeBar(false)}
                            >
                                <img src={priv} style={{ width: "30%", marginBottom: "2vh" }} />
                                {t("community")}
                            </Link>
                    </div>
                    <div className="home-btn">
                        <Link
                            to="/broadcast"
                            className="home-btn-content"
                            onClick={() => setHomeBar(false)}
                        >
                            <img src={transfert} style={{ width: "30%", marginBottom: "2vh" }} />
                            {t("Broadcast")}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

interface InfoProps {
    title: string,
    description: string
}

export const Info = ({ title, description }: InfoProps) => {
    return <div className="info-div">
        <h2 style={{ color: '#078BF9' }}>
            {title}
        </h2>
        <span style={{ color: '#E2E2E2' }}>
            {description}
        </span>
    </div>
}

export default Dashboard