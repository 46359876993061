import CardWallet from "./CardWallet";
import "../MobileApp.scss";
import { useEffect } from "react";

interface ProductWalletProps {
  setPage: any;
  collec: any;
  page: number;
  setnft: any;
  ispoap: boolean;
}

export const ProductWallet = ({
  setPage,
  collec,
  page,
  setnft,
  ispoap,
}: ProductWalletProps) => {
  console.log("collec", collec);

  useEffect(() => {
    setPage(3);
    setnft(collec);
  })
  
  return (
    <>
    </>
  );
};

export default ProductWallet;
