// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-language {
    position: absolute;
    top: -10px;
    right: 35px;
    background: #04152D;
}

.img-flag {
    width: 30px;
    height: 2vh;
    border-radius: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/Language/Language.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".img-language {\n    position: absolute;\n    top: -10px;\n    right: 35px;\n    background: #04152D;\n}\n\n.img-flag {\n    width: 30px;\n    height: 2vh;\n    border-radius: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
